import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoCart = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.9204 3.64299C21.9728 3.76936 21.9997 3.9048 21.9997 4.04158C22.003 4.18045 21.9786 4.31859 21.9277 4.44786C21.8769 4.57713 21.8007 4.69492 21.7036 4.7943C21.6066 4.89368 21.4906 4.97265 21.3626 5.02655C21.2345 5.08045 21.097 5.1082 20.9581 5.10816H19.7666C19.7163 5.10798 19.6678 5.12675 19.6307 5.16072C19.5937 5.19469 19.5708 5.24138 19.5666 5.29148L19.3749 6.74969C19.325 7.19584 18.7772 10.598 18.7333 10.866L18.1417 14.7407C18.0755 15.1852 17.8511 15.591 17.5097 15.8833C17.1684 16.1757 16.733 16.3351 16.2835 16.3322H6.00938C5.5214 16.3326 5.04611 16.1767 4.65321 15.8873C4.26032 15.5979 3.97049 15.1902 3.82623 14.724L2.10971 9.31613C1.99836 8.97207 1.97127 8.6063 2.03072 8.24959C2.09018 7.89288 2.23443 7.55566 2.45135 7.26631C2.65977 6.97144 2.93563 6.73064 3.25595 6.56395C3.57627 6.39727 3.93177 6.30954 4.29286 6.30806H17.1501C17.2004 6.30823 17.2489 6.28946 17.2859 6.25549C17.323 6.22152 17.3459 6.17484 17.3501 6.12474L17.5917 4.27489C17.6345 3.92287 17.8049 3.59873 18.0705 3.3638C18.3361 3.12888 18.6787 2.99945 19.0333 3H20.9581C21.0949 3 21.2303 3.02694 21.3567 3.07929C21.4831 3.13163 21.5979 3.20835 21.6946 3.30507C21.7913 3.40179 21.8681 3.51662 21.9204 3.64299ZM7.62576 20.9151C8.6612 20.9151 9.5006 20.0758 9.5006 19.0403C9.5006 18.0049 8.6612 17.1655 7.62576 17.1655C6.59031 17.1655 5.75092 18.0049 5.75092 19.0403C5.75092 20.0758 6.59031 20.9151 7.62576 20.9151ZM17.6249 19.0403C17.6249 20.0758 16.7855 20.9151 15.7501 20.9151C14.7146 20.9151 13.8752 20.0758 13.8752 19.0403C13.8752 18.0049 14.7146 17.1655 15.7501 17.1655C16.7855 17.1655 17.6249 18.0049 17.6249 19.0403Z"
    />
  </svg>
);
const Memo = memo(SvgIcoCart);
export default Memo;
