import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import Head from 'next/head';
import React from 'react';
import { useIntl } from 'react-intl';

import { Navigation } from 'components/@layout';
import { SimpleNavigation } from 'components/@layout/Navigation';

interface Props {
  checkoutSuccess?: boolean;
  children?: React.ReactNode;
}

const PageContainer: React.FC = ({ children, checkoutSuccess }: Props) => {
  const intl = useIntl();

  return (
    <>
      <Head>
        {/* using useIntl here, issue: https://github.com/vercel/next.js/issues/1636 */}
        <title>{intl.formatMessage({ id: 'seo.title' })} </title>
        <meta name="description" content={intl.formatMessage({ id: 'seo.description' })} />
      </Head>
      <Box minHeight="100vh" display="flex" flexDirection="column">
        <Box
          component="main"
          flex="1 auto"
          bgcolor="common.white"
          color="common.black"
          display="flex"
          flexDirection="column"
          sx={{
            backgroundImage: checkoutSuccess
              ? 'linear-gradient(180deg, #EBB3B4 1.68%, #FED2D1 55.04%);'
              : '',
          }}
        >
          {checkoutSuccess ? <SimpleNavigation /> : <Navigation />}

          <Stack
            minHeight={{ md: 'calc(100vh - 80px)' }}
            justifyContent="space-between"
            sx={{
              pt: { xs: 5, md: 8 },
              pb: { xs: 0, md: 10 },
            }}
          >
            {children}
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default PageContainer;
