import { CognitoUser } from '@aws-amplify/auth';

import { Roles } from 'types/user.types';

export const getInitialsFromUser = (user: CognitoUser) => {
  const { given_name, family_name } = user.attributes;

  return `${given_name.charAt(0).toLocaleUpperCase()}${family_name.charAt(0).toLocaleUpperCase()}`;
};

export const getPrimaryRole = (user?: CognitoUser) => {
  const session = user?.getSignInUserSession();

  if (session) {
    const roles = session.getAccessToken().payload['cognito:groups'];
    switch (true) {
      case user?.attributes['custom:is_besteller'] === 'true':
        return Roles.BESTELLER;
      case roles?.includes(Roles.ADMIN):
        return Roles.ADMIN;
      case roles?.includes(Roles.TEACHER):
        return Roles.TEACHER;
      default:
        return Roles.STUDENT;
    }
  }
};

export const isTeacher = (user?: CognitoUser) => getPrimaryRole(user) === Roles.TEACHER;

export const isAdmin = (user?: CognitoUser) => getPrimaryRole(user) === Roles.ADMIN;

export const isOrderer = (user?: CognitoUser) => getPrimaryRole(user) === Roles.BESTELLER;

export const isStudent = (user?: CognitoUser) => getPrimaryRole(user) === Roles.STUDENT;
