import { useQuery } from '@tanstack/react-query';

import config from 'config';

import { useLocale } from 'hooks';
import { QueryKeys } from 'queries/QueryKeys';
import { storefrontApiService } from 'services';

export const getCollection = async (locale: string = config.DEFAULT_LOCALE, handle: string) => {
  const data = await storefrontApiService.getCollection(locale, handle);

  return data.collection;
};

export const useCollection = (handle: string) => {
  const locale = useLocale();
  const { data, isLoading } = useQuery(QueryKeys.collections.byHandle(locale, handle), () =>
    getCollection(locale, handle),
  );

  return { data, isLoading };
};
