import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import React from 'react';

import config from 'config';

const gtmParams = {
  id: config.TAG_MANAGER.ID,
  environment: {
    gtm_auth: config.TAG_MANAGER.ENVIRONMENT.AUTH,
    gtm_preview: config.TAG_MANAGER.ENVIRONMENT.PREVIEW,
  },
};

const AnalyticsProvider: React.FC = ({ children }) => {
  return <GTMProvider state={gtmParams}>{children}</GTMProvider>;
};

export default AnalyticsProvider;
