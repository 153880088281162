import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoArrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.0625 18.0627C7.51022 18.0627 7.0625 17.615 7.0625 17.0627C7.0625 15.9852 6.63448 14.9519 5.87261 14.1901C5.11074 13.4282 4.07742 13.0002 2.99997 13.0002C2.44769 13.0002 1.99997 12.5525 1.99997 12.0002C1.99997 11.4479 2.44769 11.0002 2.99997 11.0002C4.60785 11.0002 6.14988 11.6389 7.28683 12.7758C8.42377 13.9128 9.0625 15.4548 9.0625 17.0627C9.0625 17.615 8.61478 18.0627 8.0625 18.0627Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.28683 11.2263C6.14988 12.3632 4.60785 13.002 2.99997 13.002C2.44769 13.002 1.99997 12.5543 1.99997 12.002C1.99997 11.4497 2.44769 11.002 2.99997 11.002C4.07742 11.002 5.11074 10.574 5.87261 9.81208C6.63449 9.05021 7.0625 8.01689 7.0625 6.93944C7.0625 6.38715 7.51022 5.93944 8.0625 5.93944C8.61479 5.93944 9.0625 6.38716 9.0625 6.93944C9.0625 8.54732 8.42377 10.0894 7.28683 11.2263Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.1002 12.0022C22.1002 12.5544 21.6524 13.0022 21.1002 13.0022H3.10016C2.54787 13.0022 2.10016 12.5544 2.10016 12.0022C2.10016 11.4499 2.54787 11.0022 3.10016 11.0022H21.1002C21.6524 11.0022 22.1002 11.4499 22.1002 12.0022Z"
    />
  </svg>
);
const Memo = memo(SvgIcoArrowLeft);
export default Memo;
