import { createTheme } from '@mui/material/styles';

import { baseTheme } from './base';

export const typography = createTheme({
  typography: {
    fontSize: 18,
    fontFamily: 'Neue Haas Grotesk, Arial, sans-serif',
    fontWeightRegular: 400,
    h1: {
      fontSize: '4.222rem',
      lineHeight: '88.16px',
      [baseTheme.breakpoints.down('md')]: {
        fontSize: '2.488rem',
      },
    },
    h2: {
      fontSize: '3.111rem',
      lineHeight: '64.4px',
      [baseTheme.breakpoints.down('md')]: {
        fontSize: '2.074rem',
      },
    },
    h3: {
      fontSize: '2.333rem',
      lineHeight: '48.3px',
      [baseTheme.breakpoints.down('md')]: {
        fontSize: '1.728rem',
      },
    },
    h4: {
      fontSize: '1.778rem',
      lineHeight: '40px',
      [baseTheme.breakpoints.down('md')]: {
        fontSize: '1.44rem',
      },
    },
    h5: {
      fontSize: '1.333rem',
      lineHeight: '32.4px',
      [baseTheme.breakpoints.down('md')]: {
        fontSize: '1.2rem',
      },
    },
    body1: {
      fontSize: '1rem',
      lineHeight: '31.5px',
      letterSpacing: '0.15px',
    },
    body2: {
      fontSize: '0.778rem',
      lineHeight: '23.8px',
      [baseTheme.breakpoints.down('md')]: {
        fontSize: '0.889rem',
        lineHeight: '23.8px',
      },
    },
    subtitle2: {
      fontsSize: '0.667rem',
      lineHeight: '19.92px',
      [baseTheme.breakpoints.down('md')]: {
        fontSize: '0.694rem',
        lineHeight: '16.2px',
      },
    },
  },
}).typography;
