import { Avatar, Button } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { generateTestId } from 'utils/test-id.utils';

import { IcoArrowLeft } from 'components/@icons';

interface Props {
  onClick(): void;
  target?: string;
  text: string;
}

const BackButton: React.VFC<Props> = ({ onClick, text }) => {
  return (
    <Button
      size="small"
      disableRipple
      onClick={onClick}
      startIcon={
        <Avatar sx={{ width: 32, height: 32 }} variant="outlined" color="secondary">
          <IcoArrowLeft />
        </Avatar>
      }
      variant="text"
      {...generateTestId('btn_previous_step')}
    >
      <FormattedMessage id={text} />
    </Button>
  );
};

export default BackButton;
