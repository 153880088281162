import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoCalendarCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 2C7.55228 2 8 2.44772 8 3V4H16V3C16 2.44772 16.4477 2 17 2C17.5523 2 18 2.44772 18 3V4H19.8C20.4627 4 21 4.58547 21 5.30769V8H17H7H3V5.30769C3 4.58547 3.53726 4 4.2 4H6V3C6 2.44772 6.44772 2 7 2ZM3 10V19.6923C3 20.4145 3.53726 21 4.2 21H10.5815C10.2065 20.0736 10 19.0609 10 18C10 16.4084 10.4648 14.9254 11.266 13.6792C11.1009 13.5008 11 13.2622 11 13C11 12.4477 11.4477 12 12 12C12.1975 12 12.3816 12.0572 12.5366 12.156C13.9666 10.8187 15.8877 10 18 10C19.0609 10 20.0736 10.2065 21 10.5815V10H18H3ZM8 14C8.55228 14 9 13.5523 9 13C9 12.4477 8.55228 12 8 12C7.44772 12 7 12.4477 7 13C7 13.5523 7.44772 14 8 14ZM9 17C9 17.5523 8.55228 18 8 18C7.44772 18 7 17.5523 7 17C7 16.4477 7.44772 16 8 16C8.55228 16 9 16.4477 9 17ZM18 12C14.6863 12 12 14.6863 12 18C12 21.3137 14.6863 24 18 24C21.3137 24 24 21.3137 24 18C24 14.6863 21.3137 12 18 12ZM21.2807 15.5862C21.5092 15.7412 21.5688 16.0522 21.4138 16.2807L18.2653 20.9217C18.1564 21.0818 18.0146 21.2186 17.8477 21.319C17.6806 21.4196 17.4929 21.4809 17.2974 21.4962C17.1018 21.5115 16.9066 21.48 16.7258 21.4055C16.5452 21.331 16.3853 21.2162 16.2551 21.0725L14.6292 19.2753C14.444 19.0705 14.4598 18.7543 14.6646 18.5691C14.8693 18.3838 15.1855 18.3996 15.3708 18.6044L16.9963 20.4012C17.0309 20.4393 17.0694 20.4654 17.1071 20.481C17.1446 20.4965 17.1826 20.5021 17.2194 20.4993C17.2563 20.4964 17.2948 20.4847 17.3323 20.4621C17.37 20.4394 17.4069 20.4056 17.438 20.3599L20.5862 15.7193C20.7413 15.4908 21.0522 15.4312 21.2807 15.5862Z"
    />
  </svg>
);
const Memo = memo(SvgIcoCalendarCheck);
export default Memo;
