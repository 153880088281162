import { styled } from '@mui/material';

import { ImgScribbleLine } from 'components/@icons';

interface StyledLineProps {
  isMobile: boolean;
}

export const Line = styled(ImgScribbleLine)<StyledLineProps>(({ theme, isMobile }) => ({
  width: '100%',
  fill: `${theme.palette.neutral[100]}`,
  position: 'absolute',
  bottom: isMobile ? '-8px' : '-45px',
}));
