import { Attribute } from 'types/storefront.types';

export const getAttributeValues = <T>(attributes: Attribute[]) =>
  attributes.reduce((acc, attribute) => {
    acc = {
      ...acc,
      [attribute.key]: attribute.value,
    };
    return acc;
  }, {}) as T;

export const parseAttributes = (attributes: { [key: string]: string }) =>
  Object.entries(attributes).map(([key, value]) => ({ key, value }));
