import { Button, Menu, MenuItem } from '@mui/material';
import { useRouter } from 'next/router';
import { MouseEvent, useState } from 'react';

import { IcoChevronDown, IcoFlag } from 'components/@icons';

const LanguageMenu = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const { push, pathname, asPath, query, locale, locales } = useRouter();
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSetLanguage = (lang: string) => {
    push({ pathname, query }, asPath, { locale: lang });
    handleClose();
  };

  return (
    <div>
      <Button
        id="language-menu-button"
        variant="outlined"
        startIcon={<IcoFlag />}
        endIcon={<IcoChevronDown width={10} height={5} />}
        aria-expanded={open ? 'true' : undefined}
        aria-controls={open ? 'language-menu' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
      >
        {locale?.toUpperCase()}
      </Button>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'language-menu-button',
          sx: {
            width: 96,
          },
        }}
      >
        {locales?.map(lang => (
          <MenuItem
            key={lang}
            selected={locale?.toLowerCase() === lang}
            onClick={() => handleSetLanguage(lang)}
          >
            {lang.toUpperCase()}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default LanguageMenu;
