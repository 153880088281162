import 'services/amplify';

import { EmotionCache } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { Hydrate, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { startMockServer } from 'mocks';
import type { AppProps } from 'next/app';
import { useState } from 'react';
import { Provider } from 'react-redux';

import { isDev, runInDev } from 'utils/env.utils';

import { AnalyticsProvider } from 'services';
import { IntlProvider } from 'services/i18n';
import { createQueryClient } from 'services/react-query';
import { store } from 'services/redux';
import ThemeContext from 'services/theme-context';

import { PageContainer } from 'components/@screens';

interface MyAppProps extends AppProps<{ dehydratedState: unknown }> {
  emotionCache?: EmotionCache;
}

runInDev(() => {
  startMockServer();
});

const PageWrapper = ({ Component, pageProps }: MyAppProps) => {
  return (
    <IntlProvider>
      <AnalyticsProvider>
        <PageContainer {...pageProps}>
          <Component {...pageProps} />
        </PageContainer>
      </AnalyticsProvider>
    </IntlProvider>
  );
};

function MyApp(props: MyAppProps) {
  const { pageProps } = props;
  const [queryClient] = useState(() => createQueryClient());

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <ThemeContext>
            <CssBaseline />
            <PageWrapper {...props} />
          </ThemeContext>
        </Hydrate>
        {isDev() && <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />}
      </QueryClientProvider>
    </Provider>
  );
}
export default MyApp;
