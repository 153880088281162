import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoHourglassFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.16129 2C6.58808 2 6.03835 2.22771 5.63303 2.63303C5.22771 3.03835 5 3.58808 5 4.16129V5.99381C5 7.79899 5.71711 9.53024 6.99356 10.8067C7.47305 11.2862 8.0167 11.6867 8.60434 12C8.0167 12.3133 7.47305 12.7138 6.99356 13.1933C5.71711 14.4698 5 16.201 5 18.0062V19.8387C5 20.4119 5.22771 20.9617 5.63303 21.367C5.83527 21.5692 6.07348 21.7273 6.33261 21.8348C6.3599 21.8469 6.38746 21.8577 6.41521 21.8672C6.65191 21.9542 6.90424 22 7.16129 22H16.4516C16.7085 22 16.9606 21.9543 17.1972 21.8673C17.2253 21.8578 17.2533 21.8468 17.2809 21.8346C17.5398 21.727 17.7778 21.5691 17.9799 21.367C18.3852 20.9617 18.6129 20.4119 18.6129 19.8387V18.0062C18.6129 16.201 17.8958 14.4698 16.6193 13.1933C16.1399 12.7138 15.5962 12.3133 15.0086 12C15.5962 11.6867 16.1399 11.2862 16.6193 10.8067C17.8958 9.53024 18.6129 7.79899 18.6129 5.99381V4.16129C18.6129 3.58808 18.3852 3.03835 17.9799 2.63303C17.5746 2.22771 17.0248 2 16.4516 2H7.16129ZM16.6129 18.6116V18.0062C16.6129 16.7315 16.1065 15.5089 15.2051 14.6075C14.3037 13.7061 13.0812 13.1998 11.8065 13.1998C10.5317 13.1998 9.30916 13.7061 8.40778 14.6075C7.50639 15.5089 7 16.7315 7 18.0062V18.6117C7.35471 18.1626 7.75072 17.7458 8.18273 17.3811C9.17842 16.5404 10.4176 15.9355 11.8065 15.9355C13.1953 15.9355 14.4345 16.5404 15.4302 17.3811C15.8622 17.7458 16.2582 18.1626 16.6129 18.6116ZM7.04724 4.04724C7.07749 4.01699 7.11851 4 7.16129 4H16.4516C16.4944 4 16.5354 4.01699 16.5657 4.04724C16.5959 4.07749 16.6129 4.11851 16.6129 4.16129V5.99381C16.6129 6.48166 16.5387 6.96187 16.3966 7.41936H7.21626C7.07417 6.96187 7 6.48166 7 5.99381V4.16129C7 4.11851 7.01699 4.07749 7.04724 4.04724Z"
    />
  </svg>
);
const Memo = memo(SvgIcoHourglassFilled);
export default Memo;
