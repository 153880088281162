import Image, { ImageProps as NextImageProps } from 'next/image';
import React, { useState } from 'react';

export const IMAGE_PLACEHOLDER =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mPU1Tb8DwACoAGKo/qR9AAAAABJRU5ErkJggg==';
const fallback = '/images/img_fallback.png';

export interface ImageProps extends Omit<NextImageProps, 'src' | 'alt'> {
  alt: string;
  src?: string;
}

const CustomImage: React.VFC<ImageProps> = ({ src = fallback, alt, ...rest }) => {
  const [imageError, setImageError] = useState(false);
  const srcOrFallback = imageError ? fallback : src;

  const handleImageLoadError = () => {
    setImageError(true);
  };

  return (
    <Image
      src={srcOrFallback}
      alt={alt}
      onError={handleImageLoadError}
      onLoadingComplete={result => {
        if (result.naturalWidth === 0) handleImageLoadError();
      }}
      placeholder="blur"
      blurDataURL={IMAGE_PLACEHOLDER}
      {...rest}
    />
  );
};

export default CustomImage;
