import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgLogoBlack = (props: SVGProps<SVGSVGElement>) => (
  <svg id="Logo" width="1em" height="1em" viewBox="0 0 105.026 58.609" {...props}>
    <path
      id="Path_1"
      d="M0,8.265H2.876V4.9C2.876,1.3,4.942,0,8.427,0c.689,0,1.216.041,1.662.081v2.8c-.567-.041-.73-.041-1.054-.041-2.107,0-2.876.527-2.876,2.228v3.2h3.889v2.674H6.158V29.211H2.876V10.939H0Z"
      transform="translate(0 0)"
    />
    <path
      id="Path_2"
      d="M166.826,117.51v-2.877h-.081a7.262,7.262,0,0,1-6.32,3.363c-4.294,0-7.05-2.593-7.05-6.928v-14.5h3.282v14.423c0,2.715,1.742,4.294,4.7,4.294,3.322,0,5.469-2.512,5.469-5.834V96.564h3.281V117.51Z"
      transform="translate(-140.247 -88.299)"
    />
    <g id="Group_53" transform="translate(2.881 29.034)">
      <path
        id="Path_3"
        d="M33.648,427.751h3.281v3h.081a7.344,7.344,0,0,1,6.158-3.484,5.512,5.512,0,0,1,5.469,3.727h.081a7.332,7.332,0,0,1,6.4-3.727c3.728,0,6.361,2.39,6.361,6.725V448.7H58.2V434.152a3.825,3.825,0,0,0-3.849-4.132,5.379,5.379,0,0,0-5.145,5.632V448.7H45.924V434.152c0-2.593-1.458-4.132-3.605-4.132a5.683,5.683,0,0,0-5.389,5.632V448.7H33.648Z"
        transform="translate(-33.649 -419.73)"
      />
      <path
        id="Path_4"
        d="M408.953,446.648h-.041a8.416,8.416,0,0,1-6.969,3.322c-3.97,0-7.09-1.864-7.09-5.834,0-4.943,3.889-5.955,9.116-6.766,2.957-.445,5.024-.729,5.024-3,0-2.35-1.7-3.687-4.7-3.687-3.4,0-5.1,1.459-5.226,4.619h-3.16c.122-4.051,2.8-7.09,8.346-7.09,4.457,0,7.738,1.661,7.738,6.644v9.966c0,1.7.405,2.633,2.228,2.228h.122v2.39a4.494,4.494,0,0,1-1.783.324A3.218,3.218,0,0,1,408.953,446.648Zm.081-4.821v-3.565a15.065,15.065,0,0,1-4.781,1.54c-3.281.608-5.915,1.175-5.915,4.254,0,2.8,2.188,3.4,4.335,3.4C407.049,447.458,409.034,444.257,409.034,441.827Z"
        transform="translate(-363.939 -420.598)"
      />
      <path
        id="Path_5"
        d="M654.237,433.832h.081a7.432,7.432,0,0,1,6.24-3.727,3.158,3.158,0,0,1,1.337.243v3.16h-.122a3.972,3.972,0,0,0-1.459-.2,6.04,6.04,0,0,0-6.077,6.361v11.627h-3.281V430.348h3.281Z"
        transform="translate(-598.12 -422.327)"
      />
      <path
        id="Path_6"
        d="M819.692,356.886l-3.079,2.917v8.386h-3.281V339.222h3.281V356.2l9.2-8.953h4.173l-8.022,7.536,8.994,13.41h-3.849Z"
        transform="translate(-746.597 -339.222)"
      />
      <path
        id="Path_7"
        d="M1022.1,442.324h3.363c.446,3.647,2.876,4.659,6,4.659,3.444,0,4.862-1.5,4.862-3.444,0-2.35-1.62-2.957-5.469-3.768-4.133-.851-7.819-1.7-7.819-6.28,0-3.484,2.674-5.753,7.535-5.753,5.186,0,7.738,2.471,8.225,6.563h-3.363c-.324-2.755-1.823-3.971-4.942-3.971-3,0-4.214,1.337-4.214,3,0,2.269,2.026,2.715,5.632,3.444,4.213.851,7.738,1.783,7.738,6.6,0,4.213-3.444,6.361-8.265,6.361C1025.627,449.738,1022.426,447.024,1022.1,442.324Z"
        transform="translate(-937.499 -420.163)"
      />
    </g>
    <g id="Group_54" transform="translate(33.305 0.243)">
      <path
        id="Path_8"
        d="M394.657,2.841h3.281l-5.555,28.967H389.1Z"
        transform="translate(-389.103 -2.841)"
      />
      <path
        id="Path_9"
        d="M487.917,2.841H491.2l-5.555,28.967h-3.281Z"
        transform="translate(-474.38 -2.841)"
      />
    </g>
  </svg>
);
const Memo = memo(SvgLogoBlack);
export default Memo;
