import { AppBar, Container, IconButton, Stack } from '@mui/material';

import { generateTestId } from 'utils/test-id.utils';

import { useMedia } from 'hooks/useMedia';
import { useUser } from 'queries';

import { CartButton } from 'components/@cart';
import { BackButton, LanguageMenu, ProfileButton } from 'components/@common';
import { IcoArrowLeft, Logo } from 'components/@icons';

const Navigation = () => {
  const { isMobile } = useMedia();
  const { data } = useUser();

  const handleBack = () => {
    window.location.href = 'https://www.fullmarks.eu/';
  };

  return (
    <AppBar position="static">
      <Container
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: 80,
        }}
      >
        <Logo width={86} height={48} />
        <Stack direction="row" alignItems="center" spacing={6}>
          {isMobile ? (
            <IconButton color="secondary" onClick={handleBack}>
              <IcoArrowLeft />
            </IconButton>
          ) : (
            <BackButton
              onClick={handleBack}
              text="navigation.button.back_to_website"
              {...generateTestId('btn_back_to_homepage')}
            />
          )}
          <CartButton />
          <ProfileButton user={data} />
          <LanguageMenu />
        </Stack>
      </Container>
    </AppBar>
  );
};

export default Navigation;
