import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 26.548 26.548" {...props}>
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_21" width={25.452} height={12.092} strokeWidth={2} />
      </clipPath>
    </defs>
    <g id="Group_56" transform="translate(8.55) rotate(45)">
      <g id="Group_55" transform="translate(0 0)" clipPath="url(#clip-path)">
        <path
          id="Path_10"
          d="M0,0A5.6,5.6,0,0,0,5.6,5.6"
          transform="translate(19.406 0.448)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        />
        <path
          id="Path_11"
          d="M5.6,0A5.6,5.6,0,0,0,0,5.6"
          transform="translate(19.406 6.046)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        />
        <line
          id="Line_4"
          x1={24.557}
          transform="translate(0.448 6.046)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        />
      </g>
    </g>
  </svg>
);
const Memo = memo(SvgIcoArrow);
export default Memo;
