import { alpha, Components, Theme } from '@mui/material';

export const MuiChip: Components<Theme>['MuiChip'] = {
  defaultProps: {
    size: 'medium',
  },
  styleOverrides: {
    root: {
      padding: '4px 10px',
      lineHeight: '170%',
      fontSize: 14,
    },
    colorPrimary: ({ theme }) => ({
      backgroundColor: theme.palette.neutral[800],
      color: theme.palette.neutral[100],
    }),
    colorSecondary: ({ theme }) => ({
      backgroundColor: alpha(theme.palette.neutral[100], 0.56),
      color: theme.palette.neutral[800],
    }),
    label: {
      padding: 0,
    },
  },
};
