import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgLogoWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 86 48" {...props}>
    <g clipPath="url(#clip0_980_7990)">
      <path d="M0 6.76893H2.355V4.01304C2.355 1.06468 4.04673 0 6.9004 0C7.46459 0 7.89612 0.0335785 8.26132 0.0663379V2.3595C7.79704 2.32592 7.66357 2.32592 7.39826 2.32592C5.67296 2.32592 5.04326 2.75753 5.04326 4.15062V6.77138H8.22775V8.96135H5.04245V23.9234H2.355V8.9589H0V6.76893Z" />
      <path d="M21.7641 23.9234V21.5672H21.6978C21.1465 22.4343 20.3801 23.1438 19.4731 23.6265C18.5662 24.1091 17.5497 24.3486 16.5227 24.3214C13.0066 24.3214 10.7498 22.1978 10.7498 18.6475V6.7722H13.4373V18.5844C13.4373 20.808 14.8637 22.1012 17.2858 22.1012C20.006 22.1012 21.7641 20.0439 21.7641 17.3232V6.76892H24.4507V23.9234H21.7641Z" />
      <path d="M2.35828 30.3475H5.04491V32.8045H5.11123C5.64349 31.9427 6.38475 31.2292 7.26626 30.7304C8.14776 30.2316 9.14095 29.9636 10.1537 29.9511C11.1299 29.9064 12.0943 30.1799 12.9018 30.7303C13.7094 31.2807 14.3165 32.0784 14.6319 33.0035H14.6983C15.2223 32.0753 15.9839 31.3033 16.9049 30.7669C17.8259 30.2304 18.8731 29.9489 19.9389 29.9511C22.9915 29.9511 25.1475 31.9085 25.1475 35.4588V47.5045H22.4626V35.5899C22.4975 35.1562 22.4417 34.72 22.2988 34.309C22.1559 33.8981 21.9289 33.5215 21.6324 33.2031C21.3359 32.8847 20.9763 32.6316 20.5766 32.4599C20.1769 32.2882 19.7458 32.2017 19.3108 32.2058C18.7309 32.2304 18.1615 32.3694 17.6354 32.6148C17.1093 32.8603 16.637 33.2073 16.2455 33.636C15.8539 34.0646 15.551 34.5664 15.354 35.1126C15.1571 35.6587 15.07 36.2384 15.0979 36.8183V47.5045H12.4104V35.5899C12.4104 33.4662 11.2165 32.2058 9.45848 32.2058C8.27368 32.2672 7.15704 32.7788 6.33683 33.6362C5.51662 34.4935 5.05479 35.6318 5.04573 36.8183V47.5045H2.35828V30.3475Z" />
      <path d="M39.2186 45.1131H39.185C38.5199 45.9891 37.6548 46.6932 36.6621 47.1665C35.6693 47.6398 34.5777 47.8686 33.4785 47.8338C30.2276 47.8338 27.6729 46.3072 27.6729 43.0558C27.6729 39.0076 30.8573 38.1788 35.1374 37.5146C37.5588 37.1501 39.2513 36.9175 39.2513 35.0576C39.2513 33.133 37.8593 32.038 35.4027 32.038C32.6187 32.038 31.2266 33.2329 31.1235 35.8209H28.5359C28.6358 32.5032 30.8287 30.0143 35.37 30.0143C39.0196 30.0143 41.7062 31.3746 41.7062 35.4556V43.6176C41.7062 45.0099 42.0378 45.774 43.5306 45.4423H43.6305V47.3997C43.1669 47.5864 42.6701 47.6767 42.1705 47.6651C41.8058 47.7094 41.4358 47.6769 41.0843 47.5697C40.7328 47.4625 40.4077 47.283 40.1297 47.0427C39.8517 46.8024 39.627 46.5066 39.4701 46.1743C39.3132 45.842 39.2275 45.4805 39.2186 45.1131ZM39.2849 41.1648V38.2451C38.0597 38.8817 36.7363 39.308 35.37 39.5063C32.6834 40.0043 30.5265 40.4686 30.5265 42.9903C30.5265 45.2835 32.3182 45.7749 34.0762 45.7749C37.6595 45.7765 39.2849 43.1549 39.2849 41.1648Z" />
      <path d="M48.3102 33.2009H48.3766C48.8957 32.2971 49.6381 31.5418 50.5328 31.0073C51.4275 30.4729 52.4444 30.1772 53.4861 30.1485C53.8612 30.1346 54.2348 30.2025 54.581 30.3475V32.9355H54.4811C54.0965 32.8069 53.6913 32.7514 53.2864 32.7717C52.6116 32.7666 51.9429 32.8997 51.3215 33.1627C50.7 33.4258 50.139 33.8132 49.6729 34.3012C49.2068 34.7892 48.8454 35.3674 48.6111 36.0003C48.3768 36.6332 48.2743 37.3073 48.3102 37.9813V47.5036H45.6236V30.3475H48.3102V33.2009Z" />
      <path d="M62.2126 38.245L59.6913 40.634V47.502H57.0047V23.7784H59.6913V37.6832L67.2247 30.3508H70.6418L64.073 36.5227L71.4377 47.5053H68.286L62.2126 38.245Z" />
      <path d="M71.6342 41.928H74.388C74.7532 44.9149 76.743 45.7437 79.301 45.7437C82.1211 45.7437 83.2822 44.5152 83.2822 42.9231C83.2822 40.9985 81.9557 40.5014 78.8039 39.8372C75.4197 39.1402 72.4014 38.4449 72.4014 34.6939C72.4014 31.8406 74.591 29.9823 78.5714 29.9823C82.818 29.9823 84.9077 32.006 85.3064 35.3573H82.5526C82.2873 33.101 81.0599 32.1051 78.5059 32.1051C76.0493 32.1051 75.0553 33.2001 75.0553 34.5621C75.0553 36.4203 76.7143 36.7856 79.6671 37.3826C83.1169 38.0796 86.0032 38.8429 86.0032 42.788C86.0032 46.2384 83.1832 47.9975 79.2356 47.9975C74.5223 48 71.9011 45.7773 71.6342 41.928Z" />
      <path d="M31.8195 0.198975H34.5061L29.9574 23.9226H27.2692L31.8195 0.198975Z" />
      <path d="M38.3563 0.198975H41.0446L36.4959 23.9226H33.8093L38.3563 0.198975Z" />
    </g>
    <defs>
      <clipPath id="clip0_980_7990">
        <rect width={86} height={48} />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgLogoWhite);
export default Memo;
