import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoArrowDiagonalUp = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <g clipPath="url(#clip0_8783_71791)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9829 3.44539C12.3734 3.05487 13.0066 3.05487 13.3971 3.44539C14.1589 4.20726 15.1923 4.63528 16.2697 4.63528C17.307 4.63528 18.3034 4.23858 19.056 3.52919L19.1331 3.4521C19.1358 3.44944 19.1384 3.44678 19.1411 3.44412C19.5316 3.05359 20.1648 3.05359 20.5553 3.44412L20.5582 3.44704C20.9471 3.8377 20.9465 4.46963 20.5566 4.8596C20.5346 4.88163 20.5124 4.90347 20.49 4.92512C19.769 5.68001 19.3654 6.68469 19.3654 7.73097C19.3654 8.80842 19.7934 9.84174 20.5553 10.6036C20.9458 10.9941 20.9458 11.6273 20.5553 12.0178C20.1648 12.4084 19.5316 12.4084 19.1411 12.0178C18.0773 10.954 17.4497 9.53556 17.3733 8.04034L4.85685 20.5568C4.46633 20.9473 3.83317 20.9473 3.44264 20.5568C3.05212 20.1663 3.05212 19.5331 3.44264 19.1426L15.958 6.62726C14.4636 6.55038 13.0461 5.92285 11.9829 4.8596C11.5923 4.46908 11.5923 3.83591 11.9829 3.44539Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_8783_71791">
        <rect width={24} height={24} />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgIcoArrowDiagonalUp);
export default Memo;
