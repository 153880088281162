import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoDownload = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4078 7.35844C11.7759 7.35844 12.0744 7.05996 12.0744 6.69177C12.0744 6.32358 11.7759 6.02511 11.4078 6.02511C10.4054 6.02511 9.44139 6.39746 8.70073 7.06558L8.70072 2.66667C8.70072 2.29848 8.40225 2 8.03406 2C7.66587 2 7.36739 2.29848 7.36739 2.66667L7.36739 7.06688C6.62654 6.39795 5.66195 6.02511 4.65893 6.02511C4.29074 6.02511 3.99226 6.32358 3.99226 6.69177C3.99226 7.05996 4.29074 7.35844 4.65893 7.35844C5.37723 7.35844 6.06611 7.64378 6.57402 8.1517C7.06715 8.64482 7.35047 9.30853 7.36655 10.0041C7.36623 10.025 7.36607 10.0459 7.36607 10.0668C7.36607 10.435 7.66455 10.7335 8.03274 10.7335L8.03395 10.7335C8.40213 10.7335 8.70061 10.435 8.70061 10.0668L8.70037 10.0221C8.7006 10.0148 8.70073 10.0074 8.70073 10V9.98208C8.72222 9.29452 9.00475 8.63961 9.49266 8.1517C10.0006 7.64378 10.6895 7.35844 11.4078 7.35844ZM3.99996 10.6667C3.99996 10.2985 3.70148 10 3.33329 10C2.9651 10 2.66663 10.2985 2.66663 10.6667V11.3333C2.66663 12.8061 3.86053 14 5.33329 14H10.6666C12.1394 14 13.3333 12.8061 13.3333 11.3333V10.6667C13.3333 10.2985 13.0348 10 12.6666 10C12.2984 10 12 10.2985 12 10.6667V11.3333C12 12.0697 11.403 12.6667 10.6666 12.6667H5.33329C4.59691 12.6667 3.99996 12.0697 3.99996 11.3333V10.6667Z"
    />
  </svg>
);
const Memo = memo(SvgIcoDownload);
export default Memo;
