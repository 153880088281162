import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoNotFound = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <path
      d="M43.5 19.5038V4.51778C43.5018 4.08677 43.4107 3.66044 43.2329 3.26781C43.0551 2.87518 42.7948 2.52547 42.4696 2.24249C42.1445 1.95952 41.7623 1.74992 41.3489 1.62796C40.9355 1.50601 40.5006 1.47457 40.074 1.53578C34.2823 2.4294 28.8492 4.90271 24.372 8.68377C23.8367 9.09992 23.178 9.32585 22.5 9.32585C21.822 9.32585 21.1633 9.09992 20.628 8.68377C16.151 4.90236 10.7178 2.429 4.926 1.53578C4.49936 1.47457 4.06453 1.50601 3.65113 1.62796C3.23774 1.74992 2.85547 1.95952 2.53036 2.24249C2.20525 2.52547 1.94493 2.87518 1.76713 3.26781C1.58933 3.66044 1.49822 4.08677 1.5 4.51778V31.7178C1.49932 32.4432 1.76288 33.1439 2.24141 33.6891C2.71993 34.2342 3.38065 34.5864 4.1 34.6798C7.92011 35.1149 11.6171 36.298 14.98 38.1618"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M22.5 9.32568V15.0017" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M16.508 14.4597C14.1994 13.0031 11.7013 11.8711 9.084 11.0957"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.508 21.95C13.8434 19.9846 10.746 18.6876 7.476 18.168"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.492 14.4597C30.8006 13.0031 33.2987 11.8711 35.916 11.0957"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.5 43.502C38.1274 43.502 43.5 38.1294 43.5 31.502C43.5 24.8745 38.1274 19.502 31.5 19.502C24.8726 19.502 19.5 24.8745 19.5 31.502C19.5 38.1294 24.8726 43.502 31.5 43.502Z"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46.5 46.5018L39.986 39.9878"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const Memo = memo(SvgIcoNotFound);
export default Memo;
