import { useQuery } from '@tanstack/react-query';

import { useLocale } from 'hooks/useLocale';
import { QueryKeys } from 'queries/QueryKeys';
import { UseCommonQueryOptions } from 'queries/types';
import { storefrontApiService } from 'services';
import { CustomCart } from 'types/cart.types';

const getCart = async (id: CustomCart['id'], locale: string) => {
  const data = await storefrontApiService.getCart(id, locale);

  return data;
};

export const useCart = (
  id: CustomCart['id'],
  config?: UseCommonQueryOptions<{ cart: CustomCart }>,
) => {
  const locale = useLocale();
  const { data, isLoading } = useQuery(
    QueryKeys.cart(id, locale),
    () => getCart(id, locale),
    config,
  );

  return { data: data?.cart, isLoading };
};
