import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.8995 13C22.4518 13 22.8995 12.5523 22.8995 12C22.8995 11.4477 22.4518 11 21.8995 11L13 11L13 2.10051C13 1.54822 12.5523 1.10051 12 1.10051C11.4477 1.10051 11 1.54822 11 2.10051L11 11L2.10051 11C1.54822 11 1.10051 11.4477 1.1005 12C1.1005 12.5523 1.54822 13 2.1005 13L11 13V21.8995C11 22.4518 11.4477 22.8995 12 22.8995C12.5523 22.8995 13 22.4518 13 21.8995V13L21.8995 13Z"
    />
  </svg>
);
const Memo = memo(SvgIcoPlus);
export default Memo;
