import { alpha, Components, Theme } from '@mui/material';

export const MuiIconButton: Components<Theme>['MuiIconButton'] = {
  defaultProps: {
    color: 'primary',
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      '&& svg': {
        width: 16,
        height: 16,
      },
    },
    colorPrimary: ({ theme }) => ({
      backgroundColor: theme.palette.neutral[800],
      borderRadius: '50%',
      width: 32,
      height: 32,

      '&& svg': {
        fill: theme.palette.neutral[100],
      },

      '&:hover': {
        backgroundColor: theme.palette.neutral[700],
      },
      '&:disabled': {
        backgroundColor: theme.palette.neutral[400],
      },
      '&:active': {
        backgroundColor: '#565656', // Color not defined in designs
      },
    }),
    colorSecondary: ({ theme }) => ({
      backgroundColor: theme.palette.neutral[100],
      border: `1px solid ${theme.palette.neutral[800]}`,
      borderRadius: '50%',
      '&:hover': {
        backgroundColor: alpha(theme.palette.neutral[800], 0.04),
      },
      '&:disabled': {
        border: `1px solid ${theme.palette.neutral[400]}`,
      },
      '&:active': {
        backgroundColor: '#AFAFAF', // Color not defined in designs
      },
    }),
  },
};
