import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoPencil = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.6166 2.28651C19.1459 2.09399 18.6417 1.99665 18.1332 2.00009C17.6246 2.00353 17.1218 2.1077 16.6538 2.30657C16.1874 2.50473 15.7648 2.793 15.4101 3.15483L4.92528 13.6396L10.3599 19.0743L20.8451 8.5892C21.207 8.23466 21.4953 7.81213 21.6936 7.34584C21.8925 6.87781 21.9967 6.37498 22.0002 5.86643C22.0036 5.35788 21.9062 4.85368 21.7136 4.383C21.521 3.91232 21.237 3.48449 20.878 3.12424L20.8755 3.12173C20.5152 2.7629 20.0873 2.47903 19.6166 2.28651ZM9.07174 20.3574L3.64216 14.9278C3.55533 15.0316 3.49254 15.1537 3.45877 15.2854L2.02849 20.8652C1.94871 21.1764 2.03913 21.5066 2.26633 21.7338C2.49353 21.9609 2.82375 22.0513 3.13496 21.9715L8.71467 20.5404C8.84616 20.5067 8.96809 20.444 9.07174 20.3574Z"
    />
  </svg>
);
const Memo = memo(SvgIcoPencil);
export default Memo;
