import { Components, Theme } from '@mui/material';

export const MuiBadge: Components<Theme>['MuiBadge'] = {
  defaultProps: {
    color: 'primary',
  },
  styleOverrides: {
    colorPrimary: ({ theme }) => ({
      color: theme.palette.neutral[800],
      backgroundColor: theme.palette.yellow[200],
    }),
    badge: {
      bottom: -3,
      right: -3,
      fontSize: 12,
    },
  },
};
