import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoArrowDiagonalDown = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <g clipPath="url(#clip0_8783_71785)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5552 11.9844C20.9457 12.3749 20.9457 13.0081 20.5552 13.3986C19.7933 14.1605 19.3653 15.1938 19.3653 16.2712C19.3653 17.3083 19.7618 18.3044 20.4709 19.057L20.5403 19.1265C20.5457 19.1318 20.5511 19.1372 20.5565 19.1426C20.947 19.5331 20.947 20.1663 20.5565 20.5568L20.549 20.5643C20.158 20.9486 19.5294 20.9465 19.141 20.5581C19.1186 20.5357 19.0965 20.5132 19.0745 20.4906C18.3197 19.7702 17.3155 19.3669 16.2697 19.3669C15.1922 19.3669 14.1589 19.7949 13.397 20.5568C13.0065 20.9473 12.3733 20.9473 11.9828 20.5568C11.5923 20.1663 11.5923 19.5331 11.9828 19.1426C13.0466 18.0788 14.465 17.4512 15.9602 17.3748L3.44377 4.85834C3.05325 4.46781 3.05325 3.83465 3.44377 3.44412C3.8343 3.0536 4.46746 3.0536 4.85799 3.44412L17.3733 15.9595C17.4502 14.4651 18.0777 13.0476 19.141 11.9844C19.5315 11.5939 20.1647 11.5939 20.5552 11.9844Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_8783_71785">
        <rect width={24} height={24} />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgIcoArrowDiagonalDown);
export default Memo;
