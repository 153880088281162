import { Collection, CollectionConnection } from 'types/storefront.types';

import { StorefrontApiService } from '../storefront-api-service';
import { getCollectionQuery, getCollectionsQuery } from './queries';

export default {
  getCollections(this: StorefrontApiService, locale: string) {
    return this.api.request<{ collections: CollectionConnection }>(getCollectionsQuery, {
      locale: locale.toUpperCase(),
    });
  },
  getCollection(this: StorefrontApiService, locale: string, handle: string) {
    return this.api.request<{ collection: Collection }>(getCollectionQuery, {
      locale: locale.toUpperCase(),
      handle,
    });
  },
};
