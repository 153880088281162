import { CustomCart, CustomCartCreatePayload } from 'types/cart.types';
import {
  AttributeInput,
  Cart,
  CartLine,
  CartLineInput,
  CartLinesAddPayload,
  CartLinesRemovePayload,
  CartLinesUpdatePayload,
  CartLineUpdateInput,
  MutationCartCreateArgs,
} from 'types/storefront.types';

import { StorefrontApiService } from '../storefront-api-service';
import {
  addCartLines,
  createCart,
  getCart,
  removeCartLines,
  updateCartAttributes,
  updateCartLines,
} from './queries';

export default {
  getCart(this: StorefrontApiService, cartId: string, locale: string) {
    return this.api.request<{ cart: CustomCart }>(getCart, {
      id: cartId,
      locale: locale.toUpperCase(),
    });
  },
  createCart(this: StorefrontApiService, params: MutationCartCreateArgs['input'], locale: string) {
    return this.api.request<{ cartCreate: CustomCartCreatePayload }>(createCart, {
      input: params,
      locale: locale.toUpperCase(),
    });
  },
  addCartLines(
    this: StorefrontApiService,
    cartId: Cart['id'],
    lines: CartLineInput[],
    locale: string,
  ) {
    return this.api.request<{ cartLinesAdd: CartLinesAddPayload }>(addCartLines, {
      cartId,
      lines,
      locale: locale.toUpperCase(),
    });
  },
  removeCartLines(
    this: StorefrontApiService,
    cartId: Cart['id'],
    lineIds: CartLine['id'][],
    locale: string,
  ) {
    return this.api.request<{ cartLinesRemove: CartLinesRemovePayload }>(removeCartLines, {
      cartId,
      lineIds,
      locale: locale.toUpperCase(),
    });
  },
  updateCartAttributes(
    this: StorefrontApiService,
    cartId: Cart['id'],
    attributes: AttributeInput[],
    locale: string,
  ) {
    return this.api.request<{ cartAttributesUpdate: CartLinesRemovePayload }>(
      updateCartAttributes,
      {
        cartId,
        attributes,
        locale: locale.toUpperCase(),
      },
    );
  },
  updateCartLines(
    this: StorefrontApiService,
    cartId: Cart['id'],
    lines: CartLineUpdateInput[],
    locale: string,
  ) {
    return this.api.request<{ cartLinesUpdate: CartLinesUpdatePayload }>(updateCartLines, {
      cartId,
      lines,
      locale: locale.toUpperCase(),
    });
  },
};
