import { Auth, CognitoUser } from '@aws-amplify/auth';
import { useQuery } from '@tanstack/react-query';

import { QueryKeys } from 'queries/QueryKeys';

const fetchUser = async () => {
  const data: CognitoUser = await Auth.currentAuthenticatedUser({
    bypassCache: true,
  });

  return data;
};

export const useUser = () => {
  const { data, isFetching, error } = useQuery(QueryKeys.user(), () => fetchUser());

  return {
    data,
    isFetching,
    error,
  };
};
