import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useLocale } from 'hooks';
import { QueryKeys } from 'queries/QueryKeys';
import { storefrontApiService } from 'services';
import { MutationCartCreateArgs } from 'types/storefront.types';

interface Variables {
  input: MutationCartCreateArgs['input'];
  locale: string;
}

const createCart = async ({ input, locale }: Variables) => {
  const data = await storefrontApiService.createCart(input, locale);

  return data.cartCreate;
};

export const useCreateCart = () => {
  const locale = useLocale();
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation(createCart, {
    onSuccess: ({ cart }) => {
      if (cart) {
        queryClient.invalidateQueries(QueryKeys.cart(cart.id));
      }
    },
  });

  const handleCreateCart = (input: MutationCartCreateArgs['input']) =>
    mutateAsync({ input, locale });

  return { createCart: handleCreateCart, isLoading };
};
