import { useQuery } from '@tanstack/react-query';
import { camelizeKeys } from 'humps';

import config from 'config';

import { isTeacher } from 'utils/user.utils';

import { QueryKeys } from 'queries/QueryKeys';
import { StaleTime } from 'queries/types';
import { ApiResponse } from 'types/api.types';
import { TeacherAttributes } from 'types/teacher.types';

import { useUser } from './useUser';

const getTeacher = async (token: string, userId: string) => {
  const data = await fetch(`${config.REST_API_BASE}/teachers/${userId}`, {
    headers: {
      Accept: 'application/vnd.api+json',
      Authorization: `Bearer ${token}`,
    },
  });

  const response = await data.json();

  return camelizeKeys(response) as unknown as ApiResponse<TeacherAttributes>;
};

export const useTeacher = () => {
  const { data: user } = useUser();
  const userId = user?.getUsername() ?? '';
  const token = user?.getSignInUserSession()?.getAccessToken().getJwtToken() ?? '';

  const { data, isFetching } = useQuery(QueryKeys.teacher(), () => getTeacher(token, userId), {
    enabled: !!token && !!userId && isTeacher(user),
    staleTime: StaleTime.FIFTEEN_MIN,
  });

  return {
    data: data?.data.attributes,
    isFetching,
  };
};
