import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoDots = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.33329 8.00008C5.33329 8.73646 4.73634 9.33341 3.99996 9.33341C3.26358 9.33341 2.66663 8.73646 2.66663 8.00008C2.66663 7.2637 3.26358 6.66675 3.99996 6.66675C4.73634 6.66675 5.33329 7.2637 5.33329 8.00008ZM9.33329 8.00008C9.33329 8.73646 8.73634 9.33341 7.99996 9.33341C7.26358 9.33341 6.66663 8.73646 6.66663 8.00008C6.66663 7.2637 7.26358 6.66675 7.99996 6.66675C8.73634 6.66675 9.33329 7.2637 9.33329 8.00008ZM12 9.33341C12.7363 9.33341 13.3333 8.73646 13.3333 8.00008C13.3333 7.2637 12.7363 6.66675 12 6.66675C11.2636 6.66675 10.6666 7.2637 10.6666 8.00008C10.6666 8.73646 11.2636 9.33341 12 9.33341Z"
    />
  </svg>
);
const Memo = memo(SvgIcoDots);
export default Memo;
