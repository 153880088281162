import { alpha, buttonClasses, Components, Theme } from '@mui/material';

export const MuiButton: Components<Theme>['MuiButton'] = {
  defaultProps: {
    color: 'primary',
    disableElevation: true,
    size: 'large',
  },
  styleOverrides: {
    root: {
      fontSize: '1rem',
      textTransform: 'none',
      borderRadius: 28,
      lineHeight: '175%',
    },
    sizeSmall: {
      height: 32,
    },
    sizeMedium: {
      padding: '8px 24px',
      height: 48,
    },
    sizeLarge: {
      padding: '12px 32px',
      height: 56,
    },
    containedPrimary: ({ theme }) => ({
      backgroundColor: theme.palette.neutral[800],
      color: theme.palette.neutral[100],
      '&:hover': {
        backgroundColor: theme.palette.neutral[700],
      },
      '&:disabled': {
        backgroundColor: alpha(theme.palette.neutral[800], 0.12),
      },
    }),
    outlinedPrimary: ({ theme }) => ({
      backgroundColor: theme.palette.neutral[100],
      border: `1px solid ${theme.palette.neutral[800]}`,
      color: theme.palette.neutral[800],

      '&:hover': {
        backgroundColor: alpha(theme.palette.neutral[800], 0.04),
        border: `1px solid ${theme.palette.neutral[800]}`,
      },
      '&:disabled': {
        border: `1px solid ${alpha(theme.palette.neutral[800], 0.12)}`,
        color: alpha(theme.palette.neutral[800], 0.26),
      },
    }),
    textPrimary: ({ theme }) => ({
      borderRadius: 0,
      color: theme.palette.neutral[800],
      [`&.${buttonClasses.fullWidth}`]: {
        justifyContent: 'flex-start',
      },
      '&:hover': {
        backgroundColor: 'transparent',
        [`& .${buttonClasses.startIcon}, & .${buttonClasses.endIcon}`]: {
          [`& .MuiAvatar-outlined`]: {
            backgroundColor: alpha(theme.palette.neutral[800], 0.04),
          },
          [`& .MuiAvatar-filled`]: {
            backgroundColor: theme.palette.neutral[700],
          },
        },
      },
      '&:disabled': {
        [`& .${buttonClasses.startIcon}, & .${buttonClasses.endIcon}`]: {
          // Not using avatar classes here because filled and outlined are custom variants
          [`& .MuiAvatar-outlined`]: {
            border: `1px solid ${theme.palette.neutral[400]}`,
          },
          [`& .MuiAvatar-filled`]: {
            backgroundColor: theme.palette.neutral[400],
          },
        },
      },
      '&:active': {
        [`& .${buttonClasses.startIcon}, & .${buttonClasses.endIcon}`]: {
          [`& .MuiAvatar-outlined`]: {
            backgroundColor: '#AFAFAF', // Color not defined in designs
          },
          [`& .MuiAvatar-filled`]: {
            backgroundColor: '#565656', // Color not defined in designs
          },
        },
      },
    }),
    text: ({ theme }) => ({
      '&:hover': {
        backgroundColor: 'transparent',
        [`& .${buttonClasses.startIcon}, & .${buttonClasses.endIcon}`]: {
          '.MuiAvatar-filled': {
            backgroundColor: theme.palette.neutral[700],
          },
          '.MuiAvatar-outlined': {
            backgroundColor: alpha(theme.palette.neutral[800], 0.04),
          },
        },
      },
    }),
    textSizeMedium: {
      padding: 0,
    },
    textSizeSmall: {
      padding: 0,
    },
  },
};
