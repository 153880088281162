import { Components, inputLabelClasses, Theme } from '@mui/material';

export const MuiInputLabel: Components<Theme>['MuiInputLabel'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: 0,
      color: theme.palette.neutral[800],

      '&:hover': {
        borderColor: theme.palette.neutral[800],
      },
      [`&.${inputLabelClasses.focused}`]: {
        color: theme.palette.neutral[800],

        [`&.${inputLabelClasses.error}`]: {
          color: theme.palette.status.error[200],
        },
      },
    }),
  },
};
