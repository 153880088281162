import { QueryKey } from '@tanstack/react-query';

import config from 'config';

import { Cart } from 'types/storefront.types';

export enum Keys {
  Collections = 'collections',
  Cart = 'cart',
  User = 'user',
  Profile = 'profile',
  Teacher = 'teacher',
}

export const QueryKeys = {
  collections: {
    list: (locale: string = config.DEFAULT_LOCALE): QueryKey => [Keys.Collections, locale],
    byHandle: (locale: string = config.DEFAULT_LOCALE, handle: string): QueryKey => [
      Keys.Collections,
      locale,
      handle,
    ],
  },
  cart: (id: Cart['id'], locale = config.DEFAULT_LOCALE): QueryKey => [Keys.Cart, id, locale],
  user: (): QueryKey => [Keys.User],
  profile: (): QueryKey => [Keys.Profile],
  teacher: (): QueryKey => [Keys.Teacher],
};
