import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useLocale, useLocalStorage } from 'hooks';
import { QueryKeys } from 'queries';
import { storefrontApiService } from 'services';
import { Cart, CartLine } from 'types/storefront.types';

type Variables = {
  cartId: Cart['id'];
  lineIds: CartLine['id'][];
  locale: string;
};

const removeCartLines = async ({ cartId, lineIds, locale }: Variables) => {
  const data = await storefrontApiService.removeCartLines(cartId, lineIds, locale);

  return data.cartLinesRemove;
};

export const useRemoveCartLines = () => {
  const locale = useLocale();
  const { getItem } = useLocalStorage();
  const cartId = getItem('cart_id');
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation(removeCartLines, {
    onSuccess: ({ cart }) => {
      if (cart) {
        queryClient.invalidateQueries(QueryKeys.cart(cart.id, locale));
      }
    },
  });

  const handleRemoveCartLines = async (lineIds: CartLine['id'][]) => {
    if (cartId) {
      mutateAsync({ cartId, lineIds, locale });
    }
  };

  return { removeCartLines: handleRemoveCartLines, isLoading };
};
