import { Components } from '@mui/material';

import NeueHaasGroteskWoff from 'assets/fonts/Neue-Haas-Grotesk.woff';
import NeueHaasGroteskWoff2 from 'assets/fonts/Neue-Haas-Grotesk.woff2';

export const MuiCssBaseline: Components['MuiCssBaseline'] = {
  styleOverrides: `
    html {
      font-size: 18px;
    }
    @font-face {
      font-family: 'Neue Haas Grotesk';
      font-style: normal;
      font-weight: 400;
      src: local('Neue Haas Grotesk'), url(${NeueHaasGroteskWoff}) format('woff'), url(${NeueHaasGroteskWoff2}) format('woff2');
    }
  `,
};
