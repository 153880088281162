import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoInfo = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.75 10.5C9.75 10.0858 10.0858 9.75 10.5 9.75H11.25C11.6475 9.75 12.0291 9.90782 12.3104 10.1892C12.3105 10.1892 12.3106 10.1893 12.3106 10.1894C12.3107 10.1894 12.3108 10.1895 12.3108 10.1896C12.5922 10.4709 12.75 10.8525 12.75 11.25V15C12.75 15.199 12.829 15.3898 12.9696 15.5304C13.1102 15.671 13.301 15.75 13.5 15.75H14.25C14.6642 15.75 15 16.0858 15 16.5C15 16.9142 14.6642 17.25 14.25 17.25H13.5C12.9034 17.25 12.331 17.013 11.909 16.591C11.487 16.169 11.25 15.5966 11.25 15V11.2501L11.2499 11.25H10.5C10.0858 11.25 9.75 10.9142 9.75 10.5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.625 7.5C11.8321 7.5 12 7.33212 12 7.125C12 6.91788 11.8321 6.75 11.625 6.75V6C11.0037 6 10.5 6.50366 10.5 7.125C10.5 7.74634 11.0037 8.25 11.625 8.25V7.5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.625 6C12.2463 6 12.75 6.50366 12.75 7.125C12.75 7.74634 12.2463 8.25 11.625 8.25V7.5C11.4179 7.5 11.25 7.33212 11.25 7.125C11.25 6.91788 11.4179 6.75 11.625 6.75V6Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75Z"
    />
  </svg>
);
const Memo = memo(SvgIcoInfo);
export default Memo;
