import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoNoPlannedLessons = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <g clipPath="url(#clip0_12_11)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 0C11.3284 0 12 0.671573 12 1.5V2.996H24V1.5C24 0.671573 24.6716 0 25.5 0C26.3284 0 27 0.671573 27 1.5V2.996H31.5C32.6935 2.996 33.8381 3.47011 34.682 4.31402C35.5259 5.15793 36 6.30253 36 7.496V14.964C36 15.7924 35.3284 16.464 34.5 16.464C33.6816 16.464 33.0162 15.8085 33.0003 14.9939H3V31.452C3 31.8498 3.15803 32.2314 3.43934 32.5127C3.72065 32.794 4.10218 32.952 4.5 32.952H14.958C15.7864 32.952 16.458 33.6236 16.458 34.452C16.458 35.2804 15.7864 35.952 14.958 35.952H4.5C3.30652 35.952 2.16193 35.4779 1.31802 34.634C0.474107 33.7901 0 32.6455 0 31.452V7.496C0 6.30253 0.474106 5.15794 1.31802 4.31402C2.16193 3.47011 3.30653 2.996 4.5 2.996H9V1.5C9 0.671573 9.67157 0 10.5 0ZM24 5.996V7.496C24 8.32443 24.6716 8.996 25.5 8.996C26.3284 8.996 27 8.32443 27 7.496V5.996H31.5C31.8978 5.996 32.2794 6.15404 32.5607 6.43534C32.842 6.71665 33 7.09818 33 7.496V11.9939H3V7.496C3 7.09818 3.15804 6.71665 3.43934 6.43534C3.72064 6.15404 4.10218 5.996 4.5 5.996H9V7.496C9 8.32443 9.67157 8.996 10.5 8.996C11.3284 8.996 12 8.32443 12 7.496V5.996H24ZM31.5 22.51C26.5294 22.51 22.5 26.5394 22.5 31.51C22.5 36.4806 26.5294 40.51 31.5 40.51C33.95 40.51 36.1713 39.5311 37.7942 37.943C37.8161 37.918 37.8389 37.8935 37.8627 37.8697C37.8867 37.8457 37.9112 37.8228 37.9363 37.8008C39.5224 36.1782 40.5 33.9583 40.5 31.51C40.5 26.5394 36.4706 22.51 31.5 22.51ZM40.9818 38.8658C42.5602 36.8342 43.5 34.2819 43.5 31.51C43.5 24.8826 38.1274 19.51 31.5 19.51C24.8726 19.51 19.5 24.8826 19.5 31.51C19.5 38.1374 24.8726 43.51 31.5 43.51C34.2743 43.51 36.8286 42.5686 38.8611 40.9877L45.4396 47.561C46.0256 48.1466 46.9754 48.1462 47.5609 47.5602C48.1465 46.9742 48.1461 46.0244 47.5601 45.4389L40.9818 38.8658Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_12_11">
        <rect width={48} height={48} />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgIcoNoPlannedLessons);
export default Memo;
