import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoMail = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.37254 4.41506C1.34686 4.52166 1.33325 4.63297 1.33325 4.74745V11.2121C1.33325 11.9931 1.96638 12.6262 2.74739 12.6262H13.2524C14.0335 12.6262 14.6666 11.9931 14.6666 11.2121V4.74745C14.6666 4.63296 14.653 4.52165 14.6273 4.41504L10.9514 6.9559L12.4517 8.34288C12.6975 8.5701 12.7126 8.95354 12.4853 9.19932C12.2581 9.44511 11.8747 9.46015 11.6289 9.23294L9.92974 7.66212L9.08074 8.24897C8.76307 8.46821 8.38594 8.58583 7.99997 8.58583C7.61399 8.58583 7.23714 8.46841 6.91948 8.24917L6.07011 7.66208L4.37091 9.23294C4.12513 9.46015 3.74169 9.44511 3.51447 9.19932C3.28726 8.95354 3.30231 8.5701 3.54809 8.34288L5.04842 6.95587L1.37254 4.41506ZM13.8615 3.47084L8.39206 7.2515C8.27679 7.33108 8.14004 7.37371 7.99997 7.37371C7.8599 7.37371 7.72286 7.33089 7.60759 7.2513L2.13829 3.47085C2.32275 3.38268 2.5293 3.33331 2.74739 3.33331H13.2524C13.4705 3.33331 13.6771 3.38268 13.8615 3.47084Z"
    />
  </svg>
);
const Memo = memo(SvgIcoMail);
export default Memo;
