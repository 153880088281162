import { alertClasses, Components, Theme } from '@mui/material';

export const MuiAlert: Components<Theme>['MuiAlert'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: 0,
      [`& .${alertClasses.icon}`]: {
        color: theme.palette.neutral[800],
      },
    }),
    standardInfo: ({ theme }) => ({
      backgroundColor: theme.palette.yellow[200],
      color: theme.palette.neutral[800],
    }),
    icon: {
      alignItems: 'center',
    },
  },
};
