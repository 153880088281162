import { useQuery } from '@tanstack/react-query';

import config from 'config';

import { useLocale } from 'hooks';
import { QueryKeys } from 'queries/QueryKeys';
import { storefrontApiService } from 'services';

export const getCollections = async (locale: string = config.DEFAULT_LOCALE) => {
  const data = await storefrontApiService.getCollections(locale);

  return data;
};

export const useCollections = () => {
  const locale = useLocale();
  const { data, isLoading } = useQuery(QueryKeys.collections.list(locale), () =>
    getCollections(locale),
  );

  return { data: data?.collections, isLoading };
};
