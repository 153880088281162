import { alpha, Components, menuItemClasses, Theme } from '@mui/material';

export const MuiMenuItem: Components<Theme>['MuiMenuItem'] = {
  defaultProps: {
    disableRipple: true,
    disableTouchRipple: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      '&:hover': {
        backgroundColor: alpha(theme.palette.neutral[800], 0.04),
      },

      [`&.${menuItemClasses.selected}`]: {
        backgroundColor: theme.palette.yellow[200],

        '&:hover': {
          backgroundColor: theme.palette.yellow[200],
        },
      },
    }),
  },
};
