import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoCircleCheckActive = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM17.9089 7.00264C18.0945 6.68465 17.9872 6.27642 17.6692 6.09083C17.3512 5.90524 16.943 6.01258 16.7574 6.33057L11.0505 16.1089C11.0345 16.1365 11.0127 16.1603 10.9866 16.1787C10.9606 16.1971 10.9308 16.2096 10.8995 16.2155L10.8907 16.2173C10.8635 16.2227 10.8354 16.2221 10.8085 16.2154C10.7816 16.2086 10.7566 16.1959 10.7352 16.1783L7.08305 13.257C6.79553 13.0271 6.37601 13.0737 6.14602 13.3612C5.91604 13.6487 5.96268 14.0683 6.2502 14.2982L9.89184 17.2111C10.0652 17.3529 10.2678 17.4545 10.4851 17.5089C10.7027 17.5633 10.9296 17.5688 11.1495 17.5252C11.3676 17.4837 11.5742 17.396 11.7556 17.268C11.938 17.1392 12.0908 16.9722 12.2031 16.7792L17.9089 7.00264Z"
    />
  </svg>
);
const Memo = memo(SvgIcoCircleCheckActive);
export default Memo;
