import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.66667 1.33337C5.03486 1.33337 5.33333 1.63185 5.33333 2.00004V2.66671H10.6667V2.00004C10.6667 1.63185 10.9651 1.33337 11.3333 1.33337C11.7015 1.33337 12 1.63185 12 2.00004V2.66671H13.2C13.6418 2.66671 14 3.05702 14 3.5385V5.33337H11.3333H4.66667H2V3.5385C2 3.05702 2.35817 2.66671 2.8 2.66671H4V2.00004C4 1.63185 4.29848 1.33337 4.66667 1.33337ZM2 6.66671V13.1282C2 13.6097 2.35817 14 2.8 14H13.2C13.6418 14 14 13.6097 14 13.1282V6.66671H2ZM5.33333 9.33337C5.70152 9.33337 6 9.0349 6 8.66671C6 8.29852 5.70152 8.00004 5.33333 8.00004C4.96514 8.00004 4.66667 8.29852 4.66667 8.66671C4.66667 9.0349 4.96514 9.33337 5.33333 9.33337ZM6 11.3334C6 11.7016 5.70152 12 5.33333 12C4.96514 12 4.66667 11.7016 4.66667 11.3334C4.66667 10.9652 4.96514 10.6667 5.33333 10.6667C5.70152 10.6667 6 10.9652 6 11.3334ZM8 9.33337C8.36819 9.33337 8.66667 9.0349 8.66667 8.66671C8.66667 8.29852 8.36819 8.00004 8 8.00004C7.63181 8.00004 7.33333 8.29852 7.33333 8.66671C7.33333 9.0349 7.63181 9.33337 8 9.33337ZM8.66667 11.3334C8.66667 11.7016 8.36819 12 8 12C7.63181 12 7.33333 11.7016 7.33333 11.3334C7.33333 10.9652 7.63181 10.6667 8 10.6667C8.36819 10.6667 8.66667 10.9652 8.66667 11.3334ZM10.6667 9.33337C11.0349 9.33337 11.3333 9.0349 11.3333 8.66671C11.3333 8.29852 11.0349 8.00004 10.6667 8.00004C10.2985 8.00004 10 8.29852 10 8.66671C10 9.0349 10.2985 9.33337 10.6667 9.33337ZM11.3333 11.3334C11.3333 11.7016 11.0349 12 10.6667 12C10.2985 12 10 11.7016 10 11.3334C10 10.9652 10.2985 10.6667 10.6667 10.6667C11.0349 10.6667 11.3333 10.9652 11.3333 11.3334Z"
    />
  </svg>
);
const Memo = memo(SvgIcoCalendar);
export default Memo;
