import { CognitoUser } from '@aws-amplify/auth';
import { Avatar, Button, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import config from 'config';

import { getInitialsFromUser } from 'utils/user.utils';

import { useLogout } from 'queries';

import { IcoLogout, IcoUser } from 'components/@icons';

interface Props {
  user?: CognitoUser;
}

const ProfileButton: React.FC<Props> = ({ user }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const { logout } = useLogout();
  const handleLogin = () => {
    window.open(`${config.PLATFORM}/login?origin=shop`, '_self');
  };

  const handleGoToPlatform = () => {
    window.open(`${config.PLATFORM}`, '_blank');
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    logout();
  };

  if (!user)
    return (
      <Button variant="contained" onClick={handleLogin}>
        <FormattedMessage id="navigation.login" />
      </Button>
    );

  return (
    <div>
      <Button
        size="small"
        disableRipple
        onClick={handleClick}
        endIcon={
          <Avatar alt="Profile picture" sx={{ width: 32, height: 32, fontSize: '18px !important' }}>
            {getInitialsFromUser(user)}
          </Avatar>
        }
      >
        <FormattedMessage id="navigation.my_profile" />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        id="profile-menu"
        anchorOrigin={{ vertical: 70, horizontal: -125 }}
        PaperProps={{
          sx: {
            minWidth: 280,
          },
        }}
      >
        <MenuItem>
          <Button
            size="small"
            fullWidth
            disableRipple
            onClick={handleGoToPlatform}
            startIcon={
              <Avatar variant="filled" sx={{ backgroundColor: 'neutral.800' }}>
                <IcoUser fill="white" />
              </Avatar>
            }
          >
            <FormattedMessage id="profile.menu.to_platform" />
          </Button>
        </MenuItem>
        <MenuItem>
          <Button
            fullWidth
            size="small"
            disableRipple
            onClick={handleLogout}
            startIcon={
              <Avatar variant="filled" sx={{ backgroundColor: 'neutral.800' }}>
                <IcoLogout fill="white" />
              </Avatar>
            }
          >
            <FormattedMessage id="profile.menu.logout" />
          </Button>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ProfileButton;
