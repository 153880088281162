export type User = {
  id: string;
  givenName: string;
  familyName: string;
  email: string;
  about?: string;
  active: boolean;
  archived: boolean;
  isAdmin: boolean;
  locale: string;
  language: string;
  timezone: string;
  createdDate: string;
  createdByUser?: User;
  updatedByUser?: User;
  company?: string;
  billing?: {
    vatNumber: string;
    number: string;
    street: string;
    city: string;
    country: string;
    zipcode: string;
    phoneNumber: string;
  };
};

export enum Roles {
  TEACHER = 'fuma-0001-platform-teachers',
  ADMIN = 'fuma-0001-platform-admin',
  STUDENT = 'fuma-0001-platform-student',
  BESTELLER = 'fuma-0001-platform-besteller',
}
