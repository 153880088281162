import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { Avatar, Box, Button, Stack, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { getAttributeValues } from 'utils/api.utils';

import { Routes } from 'constants/routes.constants';
import { useOrderFlow } from 'hooks';
import { useRemoveCartLines, useUpdateCartLines } from 'queries';
import { CartLineAttributes, CustomCartLine } from 'types/cart.types';
import { AttributeInput } from 'types/storefront.types';

import { IcoCopy, IcoTrash } from 'components/@icons';

interface Props {
  item: CustomCartLine;
  onClose(): void;
}

const CartItem: React.VFC<Props> = ({ item, onClose }) => {
  const { resetOrderValue, updateOrder } = useOrderFlow();
  const { push } = useRouter();
  const sendToGTM = useGTMDispatch();
  const { removeCartLines } = useRemoveCartLines();
  const { updateCartLines } = useUpdateCartLines();
  const { merchandise, attributes, quantity, id } = item;

  const collection = merchandise.product.collections.edges[0].node;
  const handleDuplicate = () => {
    updateOrder({
      collection,
      package: merchandise.product,
    });

    sendToGTM({ event: 'duplicate_product' });

    resetOrderValue('student');
    onClose();
    push(Routes.Student);
  };

  const handleRemove = () => {
    if (quantity > 1) {
      updateCartLines([{ attributes: attributes as AttributeInput[], id, quantity: quantity - 1 }]);
    } else {
      removeCartLines([id]);
    }
  };

  return (
    <Box py={4} px={6} borderBottom={1} borderColor="neutral.400">
      <Stack direction="row" justifyContent="space-between" mb={4}>
        <Box>
          <Typography>
            <FormattedMessage
              id="cart.item.title"
              values={{
                title: merchandise.product.collections.edges[0].node.title,
                hours: merchandise.product.hours?.value,
              }}
            />
          </Typography>
          <Typography variant="body2">
            {`${getAttributeValues<CartLineAttributes>(attributes).student_first_name} ${
              getAttributeValues<CartLineAttributes>(attributes).student_last_name
            }`}
          </Typography>
        </Box>
        <Box>
          <Stack direction="row" alignItems="center">
            {quantity > 1 && (
              <Typography pr={2} variant="body2" color="textSecondary">
                {quantity}x
              </Typography>
            )}
            <Typography>
              <FormattedNumber
                style="currency"
                value={merchandise.product.priceRange.maxVariantPrice.amount}
                currency="EUR"
              />
            </Typography>
          </Stack>
        </Box>
      </Stack>
      <Stack direction="row" spacing={4}>
        <Button
          onClick={handleDuplicate}
          size="medium"
          disableRipple
          startIcon={
            <Avatar sx={{ height: 24, width: 24 }} variant="outlined">
              <IcoCopy width={12} height={12} />
            </Avatar>
          }
        >
          <FormattedMessage id="label.duplicate" />
        </Button>
        <Button
          onClick={handleRemove}
          size="medium"
          disableRipple
          startIcon={
            <Avatar variant="outlined" sx={{ width: 24, height: 24 }}>
              <IcoTrash width={12} height={12} />
            </Avatar>
          }
        >
          <FormattedMessage id="label.remove" />
        </Button>
      </Stack>
    </Box>
  );
};

export default CartItem;
