import { AppBar, Container, Stack } from '@mui/material';

import { useMedia } from 'hooks';

import { CartButton } from 'components/@cart';
import { Logo } from 'components/@icons';

const SimpleNavigation = () => {
  const { isMobile } = useMedia();
  return (
    <AppBar
      sx={{
        borderBottom: isMobile ? 'default' : 0,
        backgroundColor: 'transparent',
        zIndex: 1,
      }}
      position="static"
    >
      <Container
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: 80,
        }}
      >
        <Logo width={86} height={48} />
        <Stack direction="row" alignItems="center" spacing={6}>
          <CartButton />
        </Stack>
      </Container>
    </AppBar>
  );
};

export default SimpleNavigation;
