import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoChevronDown = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 10 6" {...props}>
    <path d="M0 0.5L5 5.5L10 0.5H0Z" />
  </svg>
);
const Memo = memo(SvgIcoChevronDown);
export default Memo;
