import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useLocale, useLocalStorage } from 'hooks';
import { QueryKeys } from 'queries';
import { storefrontApiService } from 'services';
import { Cart, CartLineUpdateInput } from 'types/storefront.types';

type Variables = {
  cartId: Cart['id'];
  lines: CartLineUpdateInput[];
  locale: string;
};

const updateCartLines = async ({ cartId, lines, locale }: Variables) => {
  const data = await storefrontApiService.updateCartLines(cartId, lines, locale);

  return data.cartLinesUpdate;
};

export const useUpdateCartLines = () => {
  const locale = useLocale();
  const { getItem } = useLocalStorage();
  const cartId = getItem('cart_id');
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation(updateCartLines, {
    onSuccess: ({ cart }) => {
      if (cart) {
        queryClient.invalidateQueries(QueryKeys.cart(cart.id, locale));
      }
    },
  });

  const handleUpdateCartLines = async (lines: CartLineUpdateInput[]) => {
    if (cartId) {
      mutateAsync({ cartId, lines, locale });
    }
  };

  return { updateCartLines: handleUpdateCartLines, isLoading };
};
