import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/be';

import { registerLocale } from 'i18n-iso-countries';
import React from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';

import { useLocale } from 'hooks/useLocale';

import { intl } from './config';

registerLocale(require('i18n-iso-countries/langs/en.json'));
registerLocale(require('i18n-iso-countries/langs/nl.json'));
registerLocale(require('i18n-iso-countries/langs/fr.json'));

const IntlProvider: React.FC = ({ children }) => {
  const locale = useLocale();

  return (
    <ReactIntlProvider locale={locale} messages={intl[locale].messages}>
      {children}
    </ReactIntlProvider>
  );
};

export default IntlProvider;
