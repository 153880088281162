import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoPhone = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" {...props}>
    <path d="M9.80029 14.1496L9.80739 14.1537C10.4367 14.5544 11.1839 14.7284 11.9255 14.6471C12.6671 14.5658 13.3588 14.2339 13.8863 13.7064L14.345 13.2478C14.5498 13.0427 14.6648 12.7647 14.6648 12.4748C14.6648 12.1849 14.5498 11.9069 14.345 11.7019L12.4127 9.77081C12.2076 9.56598 11.9296 9.45092 11.6398 9.45092C11.3499 9.45092 11.0719 9.56598 10.8668 9.77081C10.7654 9.87235 10.645 9.95291 10.5124 10.0079C10.3798 10.0628 10.2377 10.0911 10.0942 10.0911C9.95066 10.0911 9.80855 10.0628 9.67597 10.0079C9.54339 9.95291 9.42295 9.87235 9.32152 9.77081L6.22972 6.67841C6.02489 6.47332 5.90984 6.19532 5.90984 5.90546C5.90984 5.6156 6.02489 5.3376 6.22972 5.13251C6.33127 5.03109 6.41183 4.91065 6.46679 4.77807C6.52175 4.64549 6.55004 4.50338 6.55004 4.35986C6.55004 4.21634 6.52175 4.07422 6.46679 3.94165C6.41183 3.80907 6.33127 3.68862 6.22972 3.5872L4.29809 1.6532C4.093 1.44836 3.81499 1.33331 3.52513 1.33331C3.23528 1.33331 2.95727 1.44836 2.75218 1.6532L2.29357 2.11181C1.76612 2.63938 1.43429 3.33102 1.35286 4.07258C1.27142 4.81413 1.44523 5.56129 1.84562 6.19076L1.85036 6.19787C3.9677 9.33223 6.6664 12.0315 9.80029 14.1496Z" />
  </svg>
);
const Memo = memo(SvgIcoPhone);
export default Memo;
