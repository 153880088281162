import { gql } from 'graphql-request';

export const getCart = gql`
  query getCart($locale: LanguageCode, $id: ID!) @inContext(language: $locale) {
    cart(id: $id) {
      id
      checkoutUrl
      estimatedCost {
        subtotalAmount {
          amount
        }
      }
      lines(first: 10) {
        edges {
          node {
            id
            quantity
            merchandise {
              ... on ProductVariant {
                id
                product {
                  title
                  description
                  priceRange {
                    maxVariantPrice {
                      amount
                    }
                  }
                  hours: metafield(namespace: "custom", key: "hours") {
                    value
                  }
                  iso: metafield(namespace: "custom", key: "iso") {
                    value
                  }
                  collections(first: 50) {
                    edges {
                      node {
                        id
                        title
                      }
                    }
                  }
                  variants(first: 50) {
                    edges {
                      node {
                        id
                      }
                    }
                  }
                }
              }
            }
            attributes {
              key
              value
            }
          }
        }
      }
      attributes {
        key
        value
      }
    }
  }
`;

export const createCart = gql`
  mutation cartCreate($locale: LanguageCode, $input: CartInput!) @inContext(language: $locale) {
    cartCreate(input: $input) {
      cart {
        id
        checkoutUrl
        lines(first: 10) {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;

export const addCartLines = gql`
  mutation cartLinesAdd($locale: LanguageCode, $cartId: ID!, $lines: [CartLineInput!]!)
  @inContext(language: $locale) {
    cartLinesAdd(cartId: $cartId, lines: $lines) {
      cart {
        id
      }
      userErrors {
        field
        message
      }
    }
  }
`;

export const removeCartLines = gql`
  mutation cartLinesRemove($locale: LanguageCode, $cartId: ID!, $lineIds: [ID!]!)
  @inContext(language: $locale) {
    cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
      cart {
        id
      }
    }
  }
`;

export const updateCartAttributes = gql`
  mutation cartAttributesUpdate(
    $locale: LanguageCode
    $attributes: [AttributeInput!]!
    $cartId: ID!
  ) @inContext(language: $locale) {
    cartAttributesUpdate(attributes: $attributes, cartId: $cartId) {
      cart {
        id
      }
      userErrors {
        field
        message
      }
    }
  }
`;

export const updateCartLines = gql`
  mutation cartLinesUpdate($locale: LanguageCode, $cartId: ID!, $lines: [CartLineUpdateInput!]!)
  @inContext(language: $locale) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      cart {
        id
      }
      userErrors {
        field
        message
      }
    }
  }
`;
