import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgImgScribbleDoubleLine = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 284.376 40.74" {...props}>
    <g id="Group_661" transform="translate(-1292.81 -3974.171)">
      <path
        id="Path_16"
        d="M98.331,7.956c7.884-.641,16.357-1.066,24.343-2.29a75.025,75.025,0,0,1,8.068-.532c3.921-.108,7.845-.222,11.8-.376s7.942-.355,11.99-.615c1.2-.078,2.51-.081,3.6-.2C169.252,2.7,180.406,2.662,191.515,1.96c7.83-.5,15.694-.688,23.61-1.457,4.38-.422,9.629-.488,14.48-.5a243.07,243.07,0,0,1,43.878,3.325,48.028,48.028,0,0,1,5.925,1.341c1.575.465,2.613,1.022,2.769,1.6.183.65,3.72,1.927,3.7,2.7-.025,1.012-1.62,1.523-4.56,1.5A117.854,117.854,0,0,1,268.44,9.29q-1.888-.211-3.789-.451l-.951-.121-.956-.1-1.916-.2c-2.551-.294-5.129-.465-7.687-.657-4.9-.264-9.8-.373-14.716-.363l-1.844-.016-1.848,0q-1.85,0-3.7-.025c-1.238,0-2.478-.01-3.721-.034l-3.741-.088c-1.785-.05-3.764-.023-5.792.077s-4.1.217-6.076.343q-8.44.51-16.814.87l-16.748.672c-3.568.142-7.27.24-10.726.542-9.131.79-18.26,1.438-27.487,2.029-4.385.275-8.624.607-13.076.774-4.6.176-9.531.732-14.322,1.1-12.672,1.009-25.3,2.263-38.039,2.982C59.752,18.413,44.158,19,29.46,21.147a151.449,151.449,0,0,1-15.354,1.439c-12.525.524-13.9.266-9.3-2.257a32.511,32.511,0,0,1,4.2-1.827c2.327-.859,5.18-1.467,10.466-2.008,7.373-.764,14.6-1.865,21.7-2.88,5.689-.81,11.116-1.654,17.435-2.024,2.274-.135,4.743-.479,7.055-.757C76.241,9.539,86.943,8.555,98.331,7.956"
        transform="translate(1291.313 3974.172)"
      />
      <path
        id="Path_17"
        d="M86.55,41.788,97.6,40.512c3.739-.428,7.486-.933,11.116-1.64a34.87,34.87,0,0,1,3.524-.453c1.3-.1,2.654-.178,3.839-.232,3.585-.165,7.17-.345,10.782-.57,3.614-.21,7.257-.422,10.953-.73,1.1-.09,2.294-.105,3.286-.238,10.153-1.31,20.357-1.368,30.512-2q2.686-.174,5.375-.307l5.385-.228c3.592-.166,7.188-.385,10.788-.774,3.984-.391,8.752-.461,13.146-.58,3.311-.107,6.623-.129,9.934-.186l9.931-.135,9.929-.024,4.963-.009c1.654,0,3.309.027,4.963.04,4.115.04,7.681.153,8.182,1.2.285.591,3.667,1.02,3.821,1.777.4,1.981-5.187,3.457-15.664,3.719-4.69.157-9.484.176-14.226.273-2.267.037-4.527.141-6.787.238l-6.78.275c-4.525.137-9.06.28-13.65.161-3.273-.075-7.256.163-10.865.415q-3.859.247-7.693.417l-7.655.32-15.263.605c-3.25.159-6.625.265-9.771.571L137.2,43.589c-4.165.384-8.345.7-12.539,1.091-3.988.354-7.843.74-11.9.976-4.19.284-8.661.949-13.013,1.43-2.88.307-5.751.676-8.623,1.042l-8.617,1.083c-2.876.338-5.744.744-8.624,1.085s-5.762.676-8.655.967c-3.33.585-6.708,1.134-10.115,1.627s-6.842.943-10.266,1.469c-3.427.509-6.852,1.026-10.248,1.592l-2.541.435-2.523.485q-2.515.5-4.993,1.052c-2.048.461-4.3.905-6.646,1.3s-4.78.775-7.2,1.045C-.646,61.574-1.925,61.4,2.036,58.6a27.816,27.816,0,0,1,3.671-2.084c2.047-1,4.6-1.785,9.369-2.672,3.334-.587,6.629-1.253,9.891-1.958s6.488-1.453,9.7-2.117c2.569-.544,5.078-1.1,7.663-1.6s5.244-.965,8.122-1.272c2.07-.23,4.3-.676,6.4-1.059,2.4-.433,4.8-.866,7.221-1.253s4.864-.735,7.329-1.075,4.954-.662,7.475-.963c2.523-.286,5.081-.531,7.677-.766"
        transform="translate(1292.811 3953.857)"
      />
    </g>
  </svg>
);
const Memo = memo(SvgImgScribbleDoubleLine);
export default Memo;
