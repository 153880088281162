import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoChevronRight = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path
      d="M9.70492 6L8.29492 7.41L12.8749 12L8.29492 16.59L9.70492 18L15.7049 12L9.70492 6Z"
      fillOpacity={0.54}
    />
  </svg>
);
const Memo = memo(SvgIcoChevronRight);
export default Memo;
