import { GraphQLClient } from 'graphql-request';

import config from 'config';

export const graphqlClient = new GraphQLClient(config.API_BASE, {
  headers: {
    'X-Shopify-Storefront-Access-Token': config.API_ACCESS_TOKEN,
    Accept: 'application/json',
  },
});
