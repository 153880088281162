import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoExternal = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8019 6.4595L9.10433 12.5892C8.70702 12.9796 8.70702 13.6125 9.10433 14.0028C9.50165 14.3932 10.1458 14.3932 10.5431 14.0028L17.4562 7.6759C17.4821 9.24767 18.1288 10.7496 19.262 11.863C19.6593 12.2534 20.3034 12.2534 20.7008 11.863C21.0981 11.4726 21.0981 10.8397 20.7008 10.4494C19.9256 9.6878 19.4902 8.6549 19.4902 7.57789C19.4902 6.53181 19.901 5.52734 20.6348 4.77269C20.6574 4.75121 20.6797 4.72954 20.702 4.70769C21.0925 4.32398 21.0992 3.70592 20.722 3.31422C20.715 3.307 20.7079 3.29985 20.7008 3.29277C20.3034 2.90241 19.6593 2.90241 19.262 3.29277C19.2617 3.29302 19.2615 3.29326 19.2612 3.2935L19.0158 3.51806C18.2716 4.139 17.3242 4.48346 16.3406 4.48346C15.2444 4.48346 14.1931 4.05562 13.418 3.29405C13.0207 2.90369 12.3765 2.90369 11.9792 3.29405C11.5819 3.68442 11.5819 4.31733 11.9792 4.70769C13.0083 5.71875 14.3635 6.33565 15.8019 6.4595ZM5.1341 7.76967C5.1977 7.70719 5.28395 7.67208 5.3739 7.67208H7.40867C7.97055 7.67208 8.42605 7.22455 8.42605 6.67249C8.42605 6.12043 7.97055 5.67289 7.40867 5.67289H5.3739C4.7443 5.67289 4.14049 5.91863 3.6953 6.35603C3.25011 6.79344 3 7.38669 3 8.00528V18.6676C3 19.2862 3.25011 19.8795 3.6953 20.3169C4.14049 20.7543 4.7443 21 5.3739 21H17.5825C18.2121 21 18.8159 20.7543 19.2611 20.3169C19.7063 19.8795 19.9564 19.2862 19.9564 18.6676V15.0024C19.9564 14.4504 19.5009 14.0028 18.939 14.0028C18.3771 14.0028 17.9216 14.4504 17.9216 15.0024V18.6676C17.9216 18.756 17.8859 18.8407 17.8223 18.9032C17.7587 18.9657 17.6725 19.0008 17.5825 19.0008H5.3739C5.28396 19.0008 5.1977 18.9657 5.1341 18.9032C5.0705 18.8407 5.03477 18.756 5.03477 18.6676V8.00528C5.03477 7.91691 5.0705 7.83216 5.1341 7.76967Z"
    />
  </svg>
);
const Memo = memo(SvgIcoExternal);
export default Memo;
