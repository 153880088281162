import humps from 'humps';

import config from 'config';

import { CreateCustomerBody } from 'types/customer.types';

export default {
  createCustomer(attributes: CreateCustomerBody) {
    return fetch(`${config.REST_API_BASE}/create-customer`, {
      method: 'POST',
      body: JSON.stringify(humps.decamelizeKeys({ data: { type: 'customer', attributes } })),
    });
  },
};
