import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoFlagOutlined = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" {...props}>
    <path d="M14.5151 3.7941C14.4327 3.62372 14.31 3.476 14.1576 3.36378C14.0113 3.24816 13.8384 3.17106 13.6546 3.13954C13.4713 3.09622 13.2803 3.09622 13.097 3.13954L11.8243 3.5032C11.2163 3.67495 10.5793 3.71891 9.95342 3.63234C9.32758 3.54576 8.72646 3.33051 8.18791 3.00016C7.48585 2.57058 6.70108 2.29384 5.88485 2.18803C5.07086 2.08632 4.24456 2.15864 3.46061 2.40017L2.54545 2.67893V1.93956C2.54545 1.77882 2.48163 1.62467 2.36797 1.51102C2.25431 1.39736 2.10013 1.3335 1.93939 1.3335C1.77865 1.3335 1.62454 1.39736 1.51089 1.51102C1.39723 1.62467 1.33333 1.77882 1.33333 1.93956V14.0608C1.33333 14.2215 1.39723 14.3757 1.51089 14.4893C1.62454 14.603 1.77865 14.6668 1.93939 14.6668C2.10013 14.6668 2.25431 14.603 2.36797 14.4893C2.48163 14.3757 2.54545 14.2215 2.54545 14.0608V11.5274L3.81816 11.1335C4.4397 10.9431 5.09469 10.8873 5.73947 10.9699C6.38426 11.0524 7.00406 11.2715 7.55758 11.6123C8.24167 12.0323 9.0051 12.3067 9.80005 12.4183C10.0652 12.4546 10.3324 12.4728 10.6 12.4729C11.1353 12.4736 11.6679 12.3981 12.1818 12.2487L13.7879 11.788C14.0408 11.7157 14.2633 11.5631 14.4219 11.3531C14.5804 11.1432 14.6663 10.8875 14.6667 10.6244V4.32744C14.6596 4.14009 14.6077 3.95717 14.5151 3.7941ZM13.4303 10.6486L11.8243 11.1093C11.2161 11.282 10.5785 11.3259 9.95238 11.2382C9.32624 11.1505 8.7253 10.9332 8.18791 10.6002C7.48539 10.1716 6.70082 9.89492 5.88485 9.78805C5.07086 9.68635 4.24456 9.75863 3.46061 10.0002L2.54545 10.279V3.97591L3.81816 3.58199C4.43909 3.38861 5.09471 3.33276 5.7394 3.41835C6.38443 3.49999 7.00444 3.71906 7.55758 4.06077C8.2453 4.47426 9.01092 4.74148 9.80656 4.84576C10.6022 4.95003 11.4108 4.88913 12.1818 4.66683L13.4545 4.29714L13.4303 10.6486Z" />
  </svg>
);
const Memo = memo(SvgIcoFlagOutlined);
export default Memo;
