import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoComment = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4795 12.7086C13.8128 12.3085 14 11.7659 14 11.2001V4.8001C14 4.2343 13.8128 3.69168 13.4795 3.29161C13.1462 2.89153 12.6942 2.66677 12.2228 2.66677H1.2636C1.14863 2.66571 1.03589 2.70484 0.939131 2.77937C0.842368 2.85391 0.765765 2.96063 0.718664 3.08653C0.671562 3.21243 0.655998 3.35206 0.673868 3.48839C0.691739 3.62473 0.742273 3.75187 0.819307 3.85432L2.74458 6.57787V11.2001C2.74458 11.7659 2.93181 12.3085 3.2651 12.7086C3.59838 13.1087 4.05041 13.3334 4.52175 13.3334H12.2228C12.6942 13.3334 13.1462 13.1087 13.4795 12.7086ZM5.33333 4.66675C4.96514 4.66675 4.66667 4.96522 4.66667 5.33341C4.66667 5.7016 4.96514 6.00008 5.33333 6.00008H11.3333C11.7015 6.00008 12 5.7016 12 5.33341C12 4.96522 11.7015 4.66675 11.3333 4.66675H5.33333ZM4.66667 8.00008C4.66667 7.63189 4.96514 7.33342 5.33333 7.33342H11.3333C11.7015 7.33342 12 7.63189 12 8.00008C12 8.36827 11.7015 8.66675 11.3333 8.66675H5.33333C4.96514 8.66675 4.66667 8.36827 4.66667 8.00008ZM5.33333 10.0001C4.96514 10.0001 4.66667 10.2986 4.66667 10.6667C4.66667 11.0349 4.96514 11.3334 5.33333 11.3334H8.66667C9.03486 11.3334 9.33333 11.0349 9.33333 10.6667C9.33333 10.2986 9.03486 10.0001 8.66667 10.0001H5.33333Z"
    />
  </svg>
);
const Memo = memo(SvgIcoComment);
export default Memo;
