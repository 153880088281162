import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/be';

import { all } from 'deepmerge';
import { createIntl, createIntlCache, IntlShape } from 'react-intl';

import MESSAGES_EN from './translations/en.json';
import MESSAGES_FR from './translations/fr.json';
import MESSAGES_NL from './translations/nl.json';

const intlCache = createIntlCache();

// Display default translation when translation is missing
const mergeDeep = (messages: object) => all([MESSAGES_NL, messages]) as Record<string, string>;

const intl: { [key: string]: IntlShape } = {
  nl: createIntl({ locale: 'nl', messages: MESSAGES_NL }, intlCache),
  en: createIntl({ locale: 'en', messages: mergeDeep(MESSAGES_EN) }, intlCache),
  fr: createIntl({ locale: 'fr', messages: mergeDeep(MESSAGES_FR) }, intlCache),
};

export { intl };
