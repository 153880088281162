import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoGlobe = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 19 19" {...props}>
    <g id="Group_61" transform="translate(0.5 0.501)">
      <circle
        id="Ellipse_6"
        cx={9}
        cy={9}
        r={9}
        transform="translate(0 -0.001)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1}
      />
      <line
        id="Line_5"
        x2={18}
        transform="translate(0 8.999)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1}
      />
      <path
        id="Path_20"
        d="M51.923,14a15.535,15.535,0,0,1-3.461,9A15.535,15.535,0,0,1,45,14a15.535,15.535,0,0,1,3.461-9A15.535,15.535,0,0,1,51.923,14Z"
        transform="translate(-39.462 -5)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1}
      />
    </g>
  </svg>
);
const Memo = memo(SvgIcoGlobe);
export default Memo;
