import { Badge, Dialog, IconButton, Menu } from '@mui/material';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

import { Routes } from 'constants/routes.constants';
import { useLocalStorage, useMedia, useOrderFlow } from 'hooks';
import { useCart } from 'queries';

import { IcoCart } from 'components/@icons';

import CartContent from '../CartContent';

const CartButton = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { isMobile } = useMedia();
  const open = Boolean(anchorEl);
  const { getItem } = useLocalStorage();
  const router = useRouter();
  const cartId = getItem('cart_id');
  const { data } = useCart(cartId ?? '', { enabled: !!cartId });
  const { setActiveStep } = useOrderFlow();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    isMobile ? setDialogOpen(true) : setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDialog = () => setDialogOpen(false);

  const handlePlaceOrder = async () => {
    // Go to billing step
    setActiveStep(4);
    await router.push(Routes.Checkout);
    handleCloseDialog();
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        id="cart-button"
        aria-controls={open ? 'cart-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Badge
          badgeContent={data?.lines.edges.length ?? 0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <IcoCart />
        </Badge>
      </IconButton>
      {isMobile ? (
        <Dialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          PaperProps={{
            sx: {
              margin: 0,
              height: '100%',
              width: '100%',
              maxHeight: '100%',
            },
          }}
        >
          <CartContent data={data} onClose={handleCloseDialog} onPlaceOrder={handlePlaceOrder} />
        </Dialog>
      ) : (
        <Menu
          id="cart-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          anchorOrigin={{
            vertical: 55,
            horizontal: 32,
          }}
          MenuListProps={{
            'aria-labelledby': 'cart-button',
            sx: {
              width: 466,
            },
          }}
        >
          <CartContent data={data} onClose={handleClose} onPlaceOrder={handlePlaceOrder} />
        </Menu>
      )}
    </div>
  );
};

export default CartButton;
