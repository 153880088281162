import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoTrash = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2 2C9.53534 2 8.9571 2.33153 8.5706 2.81464C8.18874 3.29197 8 3.90028 8 4.5V5H5H3C2.44772 5 2 5.44772 2 6C2 6.55228 2.44772 7 3 7H4V19.5C4 20.175 4.27842 20.8147 4.76148 21.2805C5.24329 21.7451 5.88928 22 6.55556 22H17.4444C18.1107 22 18.7567 21.7451 19.2385 21.2805C19.7216 20.8147 20 20.175 20 19.5V7H21C21.5523 7 22 6.55228 22 6C22 5.44772 21.5523 5 21 5H19H16V4.5C16 3.90028 15.8113 3.29197 15.4294 2.81464C15.0429 2.33153 14.4647 2 13.8 2H10.2ZM14 5V4.5C14 4.30407 13.9359 4.14932 13.8677 4.06403C13.8357 4.02405 13.8099 4.00765 13.7994 4.00221L13.7948 4H10.2052L10.2006 4.00221C10.1901 4.00765 10.1643 4.02405 10.1323 4.06403C10.0641 4.14932 10 4.30407 10 4.5V5H14ZM10 10C10.5523 10 11 10.4477 11 11V16C11 16.5523 10.5523 17 10 17C9.44772 17 9 16.5523 9 16V11C9 10.4477 9.44772 10 10 10ZM15 11C15 10.4477 14.5523 10 14 10C13.4477 10 13 10.4477 13 11V16C13 16.5523 13.4477 17 14 17C14.5523 17 15 16.5523 15 16V11Z"
    />
  </svg>
);
const Memo = memo(SvgIcoTrash);
export default Memo;
