import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoLogout = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 2C2.89543 2 2 2.89543 2 4V12C2 13.1046 2.89543 14 4 14H7.33333C8.4379 14 9.33333 13.1046 9.33333 12V11.5556C9.33333 11.1874 9.03486 10.8889 8.66667 10.8889C8.29848 10.8889 8 11.1874 8 11.5556V12C8 12.3682 7.70152 12.6667 7.33333 12.6667H4C3.63181 12.6667 3.33333 12.3682 3.33333 12V4C3.33333 3.63181 3.63181 3.33333 4 3.33333H7.33333C7.70152 3.33333 8 3.63181 8 4V4.44444C8 4.81263 8.29848 5.11111 8.66667 5.11111C9.03486 5.11111 9.33333 4.81263 9.33333 4.44444V4C9.33333 2.89543 8.4379 2 7.33333 2H4ZM11.3584 4.6263C11.3584 4.25811 11.06 3.95964 10.6918 3.95964C10.3236 3.95964 10.0251 4.25811 10.0251 4.6263C10.0251 5.62868 10.3975 6.59267 11.0656 7.33333L5.33333 7.33333C4.96514 7.33333 4.66667 7.63181 4.66667 8C4.66667 8.36819 4.96514 8.66667 5.33333 8.66667L11.0669 8.66667C10.3979 9.40752 10.0251 10.3721 10.0251 11.3751C10.0251 11.7433 10.3236 12.0418 10.6918 12.0418C11.06 12.0418 11.3584 11.7433 11.3584 11.3751C11.3584 10.6568 11.6438 9.96795 12.1517 9.46004C12.6448 8.96691 13.3085 8.68359 14.0041 8.6675C14.025 8.66783 14.0459 8.66799 14.0668 8.66799C14.435 8.66799 14.7335 8.36951 14.7335 8.00132C14.7335 7.63313 14.435 7.33466 14.0668 7.33466L14.0402 7.33453C14.0281 7.3338 14.0158 7.3334 14.0035 7.33334L11.3584 4.6263Z"
    />
  </svg>
);
const Memo = memo(SvgIcoLogout);
export default Memo;
