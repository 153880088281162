import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 10.8261C4 7.05614 7.05614 4 10.8261 4C14.596 4 17.6522 7.05614 17.6522 10.8261C17.6522 12.7071 16.8913 14.4105 15.6606 15.6451C15.658 15.6477 15.6554 15.6502 15.6528 15.6528C15.6502 15.6554 15.6477 15.658 15.6452 15.6606C14.4105 16.8913 12.7071 17.6522 10.8261 17.6522C7.05614 17.6522 4 14.596 4 10.8261ZM16.32 17.7342C14.8125 18.9348 12.9031 19.6522 10.8261 19.6522C5.95157 19.6522 2 15.7006 2 10.8261C2 5.95157 5.95157 2 10.8261 2C15.7006 2 19.6522 5.95157 19.6522 10.8261C19.6522 12.903 18.9348 14.8125 17.7342 16.32L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L16.32 17.7342Z"
    />
  </svg>
);
const Memo = memo(SvgIcoSearch);
export default Memo;
