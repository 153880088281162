import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoCopy = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.01114 1C8.00197 1 7.9928 1.00013 7.98364 1.00038C7.31744 1.01871 6.68564 1.30019 6.22648 1.78323C5.77032 2.26311 5.52146 2.9031 5.53337 3.56475V16.6908C5.52146 17.3525 5.77032 17.9924 6.22648 18.4723C6.68564 18.9554 7.31744 19.2368 7.98364 19.2552C7.9928 19.2554 8.00197 19.2556 8.01114 19.2556H18.3556C18.3648 19.2556 18.3739 19.2554 18.3831 19.2552C19.0493 19.2368 19.6811 18.9554 20.1403 18.4723C20.5964 17.9924 20.8453 17.3524 20.8334 16.6908V6.53006C20.835 5.86707 20.5828 5.22856 20.1285 4.74569L17.3007 1.77019L17.2987 1.76803C17.0719 1.52738 16.7986 1.33523 16.4954 1.20322C16.1913 1.07082 15.8634 1.00167 15.5317 1H8.01114ZM4 7.11667C4 6.56438 3.55228 6.11667 3 6.11667C2.44772 6.11667 2 6.56438 2 7.11667V20.7333C2 21.3345 2.23881 21.911 2.66389 22.3361C3.08897 22.7612 3.66551 23 4.26667 23H15.6667C16.219 23 16.6667 22.5523 16.6667 22C16.6667 21.4477 16.219 21 15.6667 21H4.26667C4.19594 21 4.12812 20.9719 4.0781 20.9219C4.02809 20.8719 4 20.8041 4 20.7333V7.11667Z"
    />
  </svg>
);
const Memo = memo(SvgIcoCopy);
export default Memo;
