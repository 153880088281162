import { Components, Theme } from '@mui/material';

export const MuiAvatar: Components<Theme>['MuiAvatar'] = {
  defaultProps: {
    color: 'primary',
  },
  variants: [
    {
      props: { variant: 'filled' },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.yellow[200],
      }),
    },
    {
      props: { variant: 'outlined' },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.neutral[100],
        border: `1px solid ${theme.palette.neutral[800]}`,
      }),
    },
  ],
  styleOverrides: {},
};
