import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 30.205 28.52" {...props}>
    <path
      id="Path_35"
      d="M40,57.364l8.9,7.119a2.084,2.084,0,0,0,1.736.434,2.17,2.17,0,0,0,1.476-1.042L66.046,40"
      transform="translate(-37.892 -37.948)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
    />
  </svg>
);
const Memo = memo(SvgIcoCheck);
export default Memo;
