import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoFlag = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path d="M20.7955 6.32182C20.6842 6.0918 20.5185 5.89239 20.3128 5.74089C20.1153 5.58479 19.8818 5.48071 19.6337 5.43817C19.3862 5.37967 19.1284 5.37967 18.8809 5.43817L17.1628 5.92911C16.342 6.16096 15.482 6.22031 14.6371 6.10344C13.7922 5.98656 12.9807 5.69597 12.2537 5.25C11.3059 4.67006 10.2465 4.29646 9.14455 4.15361C8.04567 4.01631 6.93016 4.11395 5.87183 4.44001L4.63636 4.81634V3.81818C4.63636 3.60119 4.5502 3.39309 4.39676 3.23965C4.24332 3.08621 4.03518 3 3.81818 3C3.60119 3 3.39314 3.08621 3.2397 3.23965C3.08626 3.39309 3 3.60119 3 3.81818V20.1818C3 20.3988 3.08626 20.6069 3.2397 20.7603C3.39314 20.9138 3.60119 21 3.81818 21C4.03518 21 4.24332 20.9138 4.39676 20.7603C4.5502 20.6069 4.63636 20.3988 4.63636 20.1818V16.7618L6.35453 16.23C7.19361 15.973 8.07784 15.8977 8.9483 16.0091C9.81875 16.1206 10.6555 16.4162 11.4027 16.8764C12.3263 17.4434 13.3569 17.8139 14.4301 17.9645C14.788 18.0135 15.1488 18.0381 15.51 18.0382C16.2326 18.0392 16.9516 17.9373 17.6455 17.7355L19.8137 17.1136C20.1551 17.016 20.4555 16.8099 20.6695 16.5265C20.8835 16.2431 20.9995 15.8979 21 15.5427V7.04182C20.9905 6.7889 20.9204 6.54196 20.7955 6.32182Z" />
  </svg>
);
const Memo = memo(SvgIcoFlag);
export default Memo;
