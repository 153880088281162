import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoClose = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.138 3.80458C13.3984 3.54423 13.3984 3.12212 13.138 2.86177C12.8777 2.60142 12.4556 2.60142 12.1952 2.86177L7.99996 7.05703L3.8047 2.86177C3.54435 2.60142 3.12224 2.60142 2.86189 2.86177C2.60154 3.12212 2.60154 3.54423 2.86189 3.80458L7.05715 7.99984L2.86189 12.1951C2.60154 12.4554 2.60154 12.8776 2.86189 13.1379C3.12224 13.3983 3.54435 13.3983 3.8047 13.1379L7.99996 8.94265L12.1952 13.1379C12.4556 13.3983 12.8777 13.3983 13.138 13.1379C13.3984 12.8776 13.3984 12.4554 13.138 12.1951L8.94277 7.99984L13.138 3.80458Z"
    />
  </svg>
);
const Memo = memo(SvgIcoClose);
export default Memo;
