import { Auth } from '@aws-amplify/auth';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QueryKeys } from 'queries/QueryKeys';

const logout = () => Auth.signOut();

export const useLogout = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation(logout, {
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: QueryKeys.user() });
    },
  });

  return { logout: mutateAsync, isLoading };
};
