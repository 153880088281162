import { UseInfiniteQueryOptions, UseQueryOptions } from '@tanstack/react-query';

export const ONE_MINUTE = 60000;

export enum StaleTime {
  THIRTY_SEC = ONE_MINUTE / 2,
  MINUTE = ONE_MINUTE,
  FIVE_MIN = 5 * ONE_MINUTE,
  FIFTEEN_MIN = 15 * ONE_MINUTE,
  THIRTY_MIN = 30 * ONE_MINUTE,
  FORTY_FIVE_MIN = 45 * ONE_MINUTE,
  ONE_HOUR = 60 * ONE_MINUTE,
  FOREVER = Infinity,
}

export enum RefetchTime {
  MINUTE = ONE_MINUTE,
  FIVE_MIN = 5 * ONE_MINUTE,
  FIFTEEN_MIN = 15 * ONE_MINUTE,
}

export interface UseCommonQueryOptions<T>
  extends Omit<UseQueryOptions<T>, 'refetchInterval' | 'queryFn' | 'queryKey'> {
  // Hack to get around ugly type errors, we overwrite refreshInterval to always be a number or false
  // Else we get issues with query keys and we then need to type out all generics of UseQueryOptions (which is annoying)
  refetchInterval?: number | false;
}

export interface UseCommonInfiniteQueryOptions<T>
  extends Omit<UseInfiniteQueryOptions<T>, 'refetchInterval' | 'queryFn' | 'queryKey'> {
  refetchInterval?: number | false;
}
