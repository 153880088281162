import { ThemeProvider } from '@mui/material';
import React, { ReactNode } from 'react';

import { theme } from 'theme';

interface Props {
  children: ReactNode;
}

const ThemeContext: React.FC<Props> = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ThemeContext;
