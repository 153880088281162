import { useMutation } from '@tanstack/react-query';

import { restApiService } from 'services';
import { CreateCustomerBody } from 'types/customer.types';

const createCustomer = (variables: CreateCustomerBody) => restApiService.createCustomer(variables);

export const useCreateCustomer = () => {
  const { mutateAsync } = useMutation(createCustomer);

  return {
    createCustomer: mutateAsync,
  };
};
