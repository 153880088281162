import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OrderState } from 'types/order.types';

const initialState: OrderState = {
  collection: undefined,
  package: undefined,
  student: undefined,
  activeStep: 0,
  billing: undefined,
};

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    UPDATE_ORDER_INFO: (state, action: PayloadAction<Partial<OrderState>>) => ({
      ...state,
      ...action.payload,
    }),
    RESET_ORDER_VALUE: (state, { payload }: PayloadAction<keyof OrderState>) => ({
      ...state,
      [payload]: initialState[payload],
    }),
    RESET_ORDER_INFO: () => initialState,
  },
});

export const { actions: orderActions } = orderSlice;

export default orderSlice.reducer;
