import { useAppDispatch, useAppSelector } from 'store';
import { orderActions } from 'store/order/order.slice';
import { OrderState } from 'types/order.types';

export const useOrderFlow = () => {
  const order = useAppSelector(state => state.order);
  const dispatch = useAppDispatch();

  const updateOrder = (order: Partial<OrderState>) =>
    dispatch(orderActions.UPDATE_ORDER_INFO(order));

  const resetOrderValue = (key: keyof OrderState) => {
    dispatch(orderActions.RESET_ORDER_VALUE(key));
  };

  const resetOrder = () => dispatch(orderActions.RESET_ORDER_INFO());

  const setActiveStep = (activeStep: number) =>
    dispatch(orderActions.UPDATE_ORDER_INFO({ activeStep }));

  return {
    order,
    updateOrder,
    setActiveStep,
    resetOrder,
    resetOrderValue,
  };
};
