import { Components, Theme } from '@mui/material';

import { baseTheme } from '../base';

export const MuiContainer: Components<Theme>['MuiContainer'] = {
  defaultProps: {
    disableGutters: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      [theme.breakpoints.down('lg')]: {
        paddingLeft: baseTheme.spacing(5),
        paddingRight: baseTheme.spacing(5),
      },
    }),
    disableGutters: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
};
