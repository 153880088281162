import { Box, Container, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { useMedia } from 'hooks';

import { Line } from './ErrorLayout.style';

interface Props {
  title?: string;
  description: string;
  button?: React.ReactNode;
}

const ErrorLayout = ({ title, description, button }: Props) => {
  const { isMobile } = useMedia();
  return (
    <Container sx={{ pt: { xs: 14, md: 16 } }}>
      <Box maxWidth={{ md: 466 }}>
        {title && (
          <Box display="inline-block" mb={10}>
            <Typography variant="h1">OEPS</Typography>
            <Line isMobile={isMobile} />
          </Box>
        )}
        {title && (
          <Typography variant="h4" mb={2}>
            <FormattedMessage id={title} />
          </Typography>
        )}

        <Typography mb={10}>
          <FormattedMessage id={description} />
        </Typography>
        {button}
      </Box>
    </Container>
  );
};

export default ErrorLayout;
