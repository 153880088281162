import { createTheme } from '@mui/material/styles';
// Setup theme allows to be imported at other places
export const baseTheme = createTheme({
  spacing: 4,
  palette: {
    neutral: {
      [100]: '#FFFFFF',
      [200]: '#F8F8F8',
      [300]: '#EEEEEE',
      [400]: '#DEDEDE',
      [500]: '#999999',
      [600]: '#706F6F',
      [700]: '#424242',
      [800]: '#0D0D0D',
    },
    pink: {
      [100]: '#FFF2F7',
      [200]: '#F9D0DE',
      [300]: '#D190A6',
    },
    blue: {
      [100]: '#DFF1F7',
      [200]: '#C6DFE7',
      [300]: '#8AB4C2',
    },
    yellow: {
      [100]: '#FCF5EB',
      [200]: '#F5D8B0',
      [300]: '#DBB57F',
    },
    green: {
      [100]: '#D6F5D9',
      [200]: '#A5E9AC',
      [300]: '#70C279',
    },
    status: {
      error: {
        [100]: '#FFF2F4',
        [200]: '#CC0C23',
        [300]: '#66000C',
      },
      success: {
        [100]: '#DDF0D8',
        [200]: '#71C45C',
        [300]: '#32452D',
      },
      warning: {
        [100]: '#FFD4B3',
        [200]: '#FE9A4E',
        [300]: '#CC5800',
      },
      information: {
        [100]: '#DFF1F6',
        [200]: '#AFDBE9',
        [300]: '#37A5C8',
      },
    },
  },
});
