import { Box, Button, Divider, IconButton, Stack, Typography } from '@mui/material';
import { Fragment } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { Cart } from 'types/storefront.types';

import { IcoClose } from 'components/@icons';

import CartItem from '../CartItem';

interface Props {
  data?: Cart;
  onClose(): void;
  onPlaceOrder(): void;
}

const CartContent: React.VFC<Props> = ({ data, onClose, onPlaceOrder }) => {
  return (
    <Stack height={{ xs: '100%' }} justifyContent={{ xs: 'space-between', md: 'initial' }}>
      <Box py={4}>
        <Stack
          direction="row"
          justifyContent={{ xs: 'space-between', md: 'initial' }}
          alignItems={{ xs: 'center', md: 'initial' }}
          px={6}
        >
          <Typography variant="h4">
            <FormattedMessage id="label.cart" />
          </Typography>
          <IconButton sx={{ display: { xs: 'inline-flex', md: 'none' } }} onClick={onClose}>
            <IcoClose width={16} height={16} />
          </IconButton>
        </Stack>
        {!!data?.lines.edges.length ? (
          <Box overflow="auto">
            {data.lines.edges.map(({ node }) => (
              <Fragment key={node.id}>
                <CartItem key={node.id} item={node} onClose={onClose} />
              </Fragment>
            ))}
          </Box>
        ) : (
          <Box display="flex" alignItems="center" justifyContent="center" height={100}>
            <Typography>
              <FormattedMessage id="cart.no_items" />
            </Typography>
          </Box>
        )}
      </Box>
      <Box>
        <Divider sx={{ display: { xs: 'block', md: 'none' } }} />
        <Box pt={4} px={6} pb={6}>
          <Stack direction="row" justifyContent="space-between" mb={4}>
            <Typography>
              <FormattedMessage id="label.subtotal" />
            </Typography>
            <Box textAlign="right">
              <Typography>
                <FormattedNumber
                  style="currency"
                  value={data?.estimatedCost.subtotalAmount.amount ?? 0}
                  currency="EUR"
                />
              </Typography>
              <Typography variant="body2" color="textSecondary">
                <FormattedMessage id="label.vat_exclusive" />
              </Typography>
            </Box>
          </Stack>
          <Button
            variant="contained"
            fullWidth
            onClick={onPlaceOrder}
            disabled={!data?.lines.edges.length}
          >
            <FormattedMessage id="order_flow.step.overview.button.purchase" />
          </Button>
        </Box>
      </Box>
    </Stack>
  );
};

export default CartContent;
