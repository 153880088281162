import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useLocale, useLocalStorage } from 'hooks';
import { QueryKeys } from 'queries';
import { storefrontApiService } from 'services';
import { AttributeInput, Cart } from 'types/storefront.types';

type Variables = {
  cartId: Cart['id'];
  attributes: AttributeInput[];
  locale: string;
};

const updateCartAttributes = async ({ cartId, attributes, locale }: Variables) => {
  const data = await storefrontApiService.updateCartAttributes(cartId, attributes, locale);

  return data.cartAttributesUpdate;
};

export const useUpdateCartAttributes = () => {
  const locale = useLocale();
  const { getItem } = useLocalStorage();
  const cartId = getItem('cart_id');
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(updateCartAttributes, {
    onSuccess: ({ cart }) => {
      if (cart) {
        queryClient.invalidateQueries(QueryKeys.cart(cart.id, locale));
      }
    },
  });

  const handleUpdateCartAttributes = async (attributes: Variables['attributes']) => {
    if (cartId) {
      mutateAsync({ cartId, attributes, locale });
    }
  };

  return { updateCartAttributes: handleUpdateCartAttributes, isLoading };
};
