import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useLocale, useLocalStorage } from 'hooks';
import { QueryKeys } from 'queries';
import { storefrontApiService } from 'services';
import { Cart, CartLineInput } from 'types/storefront.types';

type Variables = {
  cartId: Cart['id'];
  lines: CartLineInput[];
  locale: string;
};

const addCartLines = async ({ cartId, lines, locale }: Variables) => {
  const data = await storefrontApiService.addCartLines(cartId, lines, locale);

  return data.cartLinesAdd;
};

export const useAddCartLines = () => {
  const locale = useLocale();
  const { getItem } = useLocalStorage();
  const cartId = getItem('cart_id');
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation(addCartLines, {
    onSuccess: ({ cart }) => {
      if (cart) {
        queryClient.invalidateQueries(QueryKeys.cart(cart.id, locale));
      }
    },
  });

  const handleAddCartLines = async (lines: CartLineInput[]) => {
    if (cartId) {
      mutateAsync({ cartId, lines, locale });
    }
  };

  return { addCartLines: handleAddCartLines, isLoading };
};
