import { Components, Theme } from '@mui/material';

export const MuiAppBar: Components<Theme>['MuiAppBar'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      height: 80,
      background: theme.palette.neutral[100],
      boxShadow: 'none',
      borderBottom: `1px solid ${theme.palette.neutral[400]}`,
    }),
  },
};
