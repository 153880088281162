import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgLogoMark = (props: SVGProps<SVGSVGElement>) => (
  <svg id="Embleem" width="1em" height="1em" viewBox="0 0 59.063 101.728" {...props}>
    <path
      id="Path_8"
      d="M408.608,2.841h11.524L400.625,104.569H389.1Z"
      transform="translate(-389.102 -2.841)"
    />
    <path
      id="Path_9"
      d="M501.868,2.841h11.524L493.885,104.569H482.362Z"
      transform="translate(-454.33 -2.841)"
    />
  </svg>
);
const Memo = memo(SvgLogoMark);
export default Memo;
