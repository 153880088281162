import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgImgScribbleLine = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 261.173 11.95" {...props}>
    <path
      id="Path_14"
      d="M88.709,2.767c7.253-.19,15.036-.148,22.408-.949a64.242,64.242,0,0,1,7.415-.115c7.194.193,14.4.323,21.84.175,1.106-.022,2.3.036,3.305-.035,10.242-.728,20.466-.28,30.674-.544,7.2-.187,14.408-.059,21.649-.64,4-.322,8.786-.308,13.194-.351Q229.129.115,249.044,0c4.129-.025,7.707.032,8.231,1.066.3.587,3.7.962,3.874,1.717C261.6,4.762,256,6.326,245.442,6.754c-4.725.192-9.558.225-14.337.348-9.137.235-18.191.834-27.439.437-3.3-.141-7.314.062-10.958.191-10.386.368-20.613.278-30.879.261-3.28-.005-6.681-.071-9.863.076-8.409.389-16.813.6-25.3.736-4.034.064-7.938.168-12.03.11-4.231-.06-8.777.223-13.189.34-11.671.31-23.317.828-35.036.762-13.606.866-27.934.415-41.537,1.528a125.055,125.055,0,0,1-14.162.337C-.8,11.474-2.041,11.116,2.333,8.941A28.179,28.179,0,0,1,6.3,7.429c2.187-.684,4.837-1.076,9.711-1.239,6.8-.228,13.487-.826,20.048-1.354,5.257-.423,10.274-.9,16.081-.865,2.09.013,4.369-.169,6.5-.3,9.754-.618,19.6-.958,30.065-.9"
      transform="translate(0 0)"
    />
  </svg>
);
const Memo = memo(SvgImgScribbleLine);
export default Memo;
