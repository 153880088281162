import { GraphQLClient } from 'graphql-request';

import cartApi from './cart';
import productsApi from './products';

interface Options {
  api: GraphQLClient;
}

export class StorefrontApiService {
  public api: GraphQLClient;

  constructor(options: Options) {
    this.api = options.api;
  }

  // PRODUCTS
  public getCollections = productsApi.getCollections;
  public getCollection = productsApi.getCollection;

  // CART
  public getCart = cartApi.getCart;
  public createCart = cartApi.createCart;
  public addCartLines = cartApi.addCartLines;
  public removeCartLines = cartApi.removeCartLines;
  public updateCartAttributes = cartApi.updateCartAttributes;
  public updateCartLines = cartApi.updateCartLines;
}
