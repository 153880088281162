import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoEye = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00619 4.00084C10.4669 3.95641 12.8887 5.67277 14.3551 7.28915C14.5557 7.51488 14.6666 7.80641 14.6666 8.10845C14.6666 8.41048 14.5557 8.70202 14.3551 8.92775C13.3719 9.99978 10.9279 12.2216 8.00619 12.2216C5.08448 12.2216 2.63492 10.0109 1.65732 8.95552C1.44888 8.7281 1.33325 8.43082 1.33325 8.12234C1.33325 7.81385 1.44888 7.51657 1.65732 7.28915C3.12372 5.67277 5.54551 4.04528 8.00619 4.00084ZM5.8817 6.69169C5.60094 7.11187 5.45109 7.60587 5.45109 8.11123C5.45109 8.78888 5.72028 9.43878 6.19946 9.91795C6.67863 10.3971 7.32853 10.6663 8.00619 10.6663C8.51154 10.6663 9.00554 10.5165 9.42572 10.2357C9.84591 9.95496 10.1734 9.5559 10.3668 9.08902C10.5602 8.62214 10.6108 8.10839 10.5122 7.61275C10.4136 7.11711 10.1703 6.66183 9.81292 6.3045C9.45558 5.94716 9.0003 5.70381 8.50466 5.60522C8.00902 5.50663 7.49528 5.55723 7.02839 5.75062C6.56151 5.94401 6.16246 6.2715 5.8817 6.69169ZM9.11703 8.11123C9.11703 8.72477 8.61966 9.22215 8.00612 9.22215C7.39258 9.22215 6.8952 8.72477 6.8952 8.11123C6.8952 7.49769 7.39258 7.00032 8.00612 7.00032C8.61966 7.00032 9.11703 7.49769 9.11703 8.11123Z"
    />
  </svg>
);
const Memo = memo(SvgIcoEye);
export default Memo;
