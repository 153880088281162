import { NextRouter } from 'next/router';

import config from 'config';

import { Locale } from 'types/i18n.types';

type NextRouterLocaleProps = Pick<NextRouter, 'locale' | 'defaultLocale'>;
type DefaultLocale = Pick<NextRouter, 'defaultLocale'>;

export const getLocale = ({ locale, defaultLocale }: NextRouterLocaleProps) =>
  (locale ?? defaultLocale ?? config.DEFAULT_LOCALE) as Locale;

export const getDefaultLocale = (defaultLocale: DefaultLocale) =>
  (defaultLocale ?? config.DEFAULT_LOCALE) as unknown as Locale;
