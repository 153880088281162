import { Components, filledInputClasses, Theme } from '@mui/material';

export const MuiFilledInput: Components<Theme>['MuiFilledInput'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      border: `1px solid ${theme.palette.neutral[400]}`,
      backgroundColor: theme.palette.neutral[100],
      borderColor: theme.palette.neutral[400],
      borderRadius: 0,
      outlineOffset: -1,
      height: 56,
      color: theme.palette.neutral[800],
      [`&.${filledInputClasses.focused}`]: {
        borderColor: theme.palette.neutral[800],
        borderWidth: 1,
        backgroundColor: theme.palette.neutral[100],
      },
      '&:hover': {
        borderColor: theme.palette.neutral[800],
        backgroundColor: theme.palette.neutral[100],
      },
      [`&.${filledInputClasses.error}`]: {
        borderColor: theme.palette.status.error[200],
      },
    }),
  },
};
