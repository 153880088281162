import config from 'config';

export const startMockServer = () => {
  if (config.API_MOCKING_ENABLED) {
    if (typeof window === 'undefined') {
      const { server } = require('./server');

      server.listen();
    } else {
      const { worker } = require('./browser');
      worker.start({ onUnhandledRequests: 'bypass' });
    }
  }
};
