import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoChevronLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path
      d="M15.7049 7.41L14.2949 6L8.29492 12L14.2949 18L15.7049 16.59L11.1249 12L15.7049 7.41Z"
      fillOpacity={0.54}
    />
  </svg>
);
const Memo = memo(SvgIcoChevronLeft);
export default Memo;
