import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0377 5.93945C16.5899 5.93945 17.0377 6.38717 17.0377 6.93945C17.0377 8.0169 17.4657 9.05022 18.2275 9.8121C18.9594 10.544 19.9418 10.9678 20.9732 11H21C21.0111 11 21.0221 11.0002 21.0332 11.0005C21.0555 11.0003 21.0778 11.0002 21.1002 11.0002C21.6517 11.0002 22.0989 11.4466 22.1002 11.9978L22.1002 12.0002L22.1002 12.002C22.1002 12.5543 21.6525 13.002 21.1002 13.002C21.0688 13.002 21.0375 13.0017 21.0062 13.0013C19.9628 13.0254 18.9672 13.4504 18.2275 14.1901C17.4657 14.9519 17.0377 15.9852 17.0377 17.0627C17.0377 17.615 16.5899 18.0627 16.0377 18.0627C15.4854 18.0627 15.0377 17.615 15.0377 17.0627C15.0377 15.5582 15.5969 14.1113 16.6003 13L3 13C2.44772 13 2 12.5523 2 12C2 11.4477 2.44772 11 3 11L16.5984 11C15.5962 9.889 15.0377 8.44302 15.0377 6.93945C15.0377 6.38717 15.4854 5.93945 16.0377 5.93945Z"
    />
  </svg>
);
const Memo = memo(SvgIcoArrowRight);
export default Memo;
