import { gql } from 'graphql-request';

export const getCollectionsQuery = gql`
  query getCollections($locale: LanguageCode) @inContext(language: $locale) {
    collections(first: 50, sortKey: TITLE) {
      edges {
        node {
          id
          title
          handle
          products(first: 50) {
            edges {
              node {
                id
                title
                description
                descriptionHtml
                priceRange {
                  maxVariantPrice {
                    amount
                  }
                }
                hours: metafield(namespace: "custom", key: "hours") {
                  value
                }
                iso: metafield(namespace: "custom", key: "iso") {
                  value
                }
                tags
                variants(first: 50) {
                  edges {
                    node {
                      id
                    }
                  }
                }
                collections(first: 50) {
                  edges {
                    node {
                      title
                    }
                  }
                }
              }
            }
          }
          metafield(namespace: "custom", key: "new") {
            value
          }
        }
      }
    }
  }
`;

export const getCollectionQuery = gql`
  query getCollectionByHandle($locale: LanguageCode, $handle: String)
  @inContext(language: $locale) {
    collection(handle: $handle) {
      id
      title
      products(first: 50) {
        edges {
          node {
            id
            handle
            title
            description
            descriptionHtml
            priceRange {
              maxVariantPrice {
                amount
              }
            }
            hours: metafield(namespace: "custom", key: "hours") {
              value
            }
            iso: metafield(namespace: "custom", key: "iso") {
              value
            }
            tags
            variants(first: 50) {
              edges {
                node {
                  id
                }
              }
            }
            collections(first: 50) {
              edges {
                node {
                  title
                }
              }
            }
          }
        }
      }
      metafield(namespace: "custom", key: "new") {
        value
      }
    }
  }
`;
