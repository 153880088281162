import type { BrowserOptions, EdgeOptions, NodeOptions } from '@sentry/nextjs';
import type { Options } from '@sentry/types';

import { isProd } from 'utils/env.utils';

type SentryOptions = Options | BrowserOptions | EdgeOptions | NodeOptions;

export interface ENV_CONFIG {
  NAME: string;
  VERSION: string;
  REST_API_BASE: string;
  PLATFORM: string;
  API_BASE: string;
  COOKIE_DOMAIN: string;
  API_CLIENT_URL: string;
  API_ACCESS_TOKEN: string;
  API_SERVER_URL: string;
  PORT?: number;
  LOCAL_STORAGE_ID: string;
  DEFAULT_CURRENCY: string;
  DEFAULT_LOCALE: string;
  DEFAULT_LOCALE_DATE: string;
  API_MOCKING_ENABLED: boolean;
  TAG_MANAGER: {
    ID: string;
    ENVIRONMENT: {
      AUTH: string;
      PREVIEW: string;
    };
  };
  AMPLIFY: {
    REGION: string;
    USER_POOL_ID: string;
    CLIENT_ID: string;
  };
  SENTRY: SentryOptions;
}

const config: ENV_CONFIG = {
  // general
  NAME: process.env.NEXT_PUBLIC_NAME || '',
  VERSION: process.env.NEXT_PUBLIC_VERSION || '',
  COOKIE_DOMAIN: process.env.COOKIE_DOMAIN || '',
  PLATFORM: process.env.PLATFORM || '',

  // api
  REST_API_BASE: process.env.REST_API_BASE || '',
  API_BASE: process.env.API_BASE || '',
  API_ACCESS_TOKEN: process.env.API_ACCESS_TOKEN || '',
  API_CLIENT_URL: process.env.NEXT_PUBLIC_API_CLIENT_URL || '',
  API_SERVER_URL: process.env.NEXT_PUBLIC_API_SERVER_URL || '',

  // analytics
  TAG_MANAGER: {
    ID: process.env.TAG_MANAGER_ID || '',
    ENVIRONMENT: {
      AUTH: process.env.TAG_MANAGER_ENVIRONMENT_AUTH || '',
      PREVIEW: process.env.TAG_MANAGER_ENVIRONMENT_PREVIEW || '',
    },
  },
  // error tracking
  SENTRY: {
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN || '',
    environment: process.env.NEXT_PUBLIC_BUILD_ENV,
    sampleRate: 0.1,
    tracesSampleRate: 0.1,
    release: isProd()
      ? `${process.env.NEXT_PUBLIC_NAME}@${process.env.NEXT_PUBLIC_VERSION}`
      : `${process.env.NEXT_PUBLIC_NAME}@${process.env.NEXT_PUBLIC_VERSION}-${process.env.NEXT_PUBLIC_BUILD_ENV}`,
  },

  // amplify
  AMPLIFY: {
    REGION: process.env.AMPLIFY_REGION || '',
    USER_POOL_ID: process.env.AMPLIFY_USER_POOL_ID || '',
    CLIENT_ID: process.env.AMPLIFY_CLIENT_ID || '',
  },

  // dev
  PORT: Number(process.env.NEXT_PUBLIC_PORT ?? 3000),
  LOCAL_STORAGE_ID: process.env.NEXT_PUBLIC_LOCAL_STORAGE_ID ?? '',
  DEFAULT_CURRENCY: process.env.NEXT_PUBLIC_DEFAULT_CURRENCY ?? 'EUR',
  DEFAULT_LOCALE: process.env.NEXT_PUBLIC_DEFAULT_LOCALE ?? 'nl',
  DEFAULT_LOCALE_DATE: process.env.NEXT_PUBLIC_DEFAULT_LOCALE_DATE ?? 'nl',

  // mocking
  API_MOCKING_ENABLED: process.env.NEXT_PUBLIC_API_MOCKING === 'true',
};

export default config;
