import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { Avatar, Box, Button, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import { VFC } from 'react';
import { FormattedMessage } from 'react-intl';

import { generateTestId } from 'utils/test-id.utils';

import { IcoArrowDiagonalUp } from 'components/@icons';

interface Props {
  text: string;
  link: {
    text: string;
    url: string;
  };
}

const InfoBox: VFC<Props> = ({ text, link }) => {
  const sendToGTM = useGTMDispatch();
  const { asPath } = useRouter();

  const handleGoToLink = () => {
    sendToGTM({ event: 'contact_click', value: asPath });
    window.open(link.url, '_blank');
  };

  return (
    <Box display={{ md: 'inline-block' }} bgcolor="neutral.200" p={{ xs: 5, md: 8 }}>
      <Typography mb={4} variant="h5">
        <FormattedMessage id={text} />
      </Typography>
      <Button
        size="small"
        onClick={handleGoToLink}
        endIcon={
          <Avatar sx={{ width: 32, height: 32, bgcolor: 'neutral.800' }}>
            <IcoArrowDiagonalUp fill="white" />
          </Avatar>
        }
        {...generateTestId('btn_contact')}
      >
        {link.text}
      </Button>
    </Box>
  );
};

export default InfoBox;
