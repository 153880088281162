import { Auth } from '@aws-amplify/auth';
import type { AuthOptions } from '@aws-amplify/auth/lib/types';

import config from 'config';

const { AMPLIFY, COOKIE_DOMAIN } = config;

const cookieStorage: AuthOptions['cookieStorage'] = {
  domain: COOKIE_DOMAIN,
  sameSite: 'lax',
  expires: 365,
  secure: false,
};

export const authConfig: AuthOptions = {
  region: AMPLIFY.REGION,
  userPoolId: AMPLIFY.USER_POOL_ID,
  userPoolWebClientId: AMPLIFY.CLIENT_ID,
  cookieStorage: cookieStorage,
};

Auth.configure({ Auth: authConfig });
